import React, { useEffect, useState } from "react";
import { Modal, Spin, Row } from 'antd-min';
import { StateType } from '@app/states';
import { SchoolLocale } from '@app/locales/localeid';
import { fmtMsg } from '@app/util';
import { GLRichTextEditor } from '@app/components';
import { getTemplate4Preview } from '@app/states/invitation-template';
import { InvitationTemplate4PreviewModel } from '@app/service/invitation-template';
import { connect } from "gl-commonui";
import './preview.less';

interface PreviewProps {
    visible: boolean
    templateId4Preview?: string
    template4Preview?: InvitationTemplate4PreviewModel
    getTemplate4Preview?: (id) => void
    onCancel?: (value?: any) => void,
    isEmailTemplate?: boolean
}


export const PreviewTemplate =
    connect(({ invitationTemplate: { template4Preview } }: StateType) => ({ template4Preview }), { getTemplate4Preview })((props: PreviewProps) => {

        const [loading4Preview, setLoading4Preview] = useState(true);

        const { visible, templateId4Preview, template4Preview, getTemplate4Preview, onCancel } = props;

        const currentTemplate4Preview = (visible && template4Preview.id && templateId4Preview == template4Preview.id) ? template4Preview : {};

        useEffect(() => {
            getTemplate4Preview(templateId4Preview);
        }, [templateId4Preview]);

        useEffect(() => {
            currentTemplate4Preview.id && setLoading4Preview(false);
        }, [template4Preview]);

        const renderTemplate = () => {
            const inlineStyle = template4Preview.backgroundImage ?
                { backgroundImage: `url(${template4Preview.backgroundImage})`, backgroundSize: `auto auto`, backgroundRepeat: 'no-repeat' } :
                {};
            return (
                <div style={inlineStyle} >
                    <GLRichTextEditor readOnly={true} forceRender={true} editorHtml={template4Preview.text} />
                </div>
            )
        }

        return (
            <Modal
                className='student-preview'
                wrapClassName="vertical-center-modal"
                title={props.isEmailTemplate ? fmtMsg({ id: SchoolLocale.StudetnInviteTemplatePreviewTitle }) : fmtMsg({ id: SchoolLocale.InvitationTemplatePreviewDialogTitle })}
                visible={visible}
                width={750}
                footer={null}
                destroyOnClose={true}
                onCancel={onCancel}
            >
                <Spin spinning={loading4Preview}>
                    {props.isEmailTemplate && <Row><span className="student-preview-title">{fmtMsg({ id: SchoolLocale.StudetnInviteTemplatePreviewSubject })}:</span>{template4Preview.title}</Row>}
                    {renderTemplate()}
                </Spin>

            </Modal>
        )
    });


PreviewTemplate.defaultProps = {
    isEmailTemplate: true
};
