import React, { useState, useRef, useEffect } from "react";
import { Tooltip, Icon, Input, Form, Radio, Select, InputNumber } from "antd-min";
import { GLForm, GLFormComponentProps, LanguageDateFormat, GLGlobal, withRouter, FormHelper, FormItemsLayout } from "gl-commonui";
import moment from "moment";
import { RouteComponentProps } from "react-router";
import { langsDateMasks } from "@app/util/consts";
import DatePicker from "react-datepicker";
import { Gender, InvitationChannel, RegistrationStepTriggerType } from "@app/util/enum";
import { generateGuid, fmtMsg, inviteEmailValidation, isNumeric } from "@app/util";
import { RegistrationNavigation } from "./navigation";
import { SchoolLocale } from "@app/locales/localeid";
import { StudentDetailsModelProps } from "@app/service/school/student-registration";
import { forwardRef } from "react";
import { RadioChangeEvent } from "antd/lib/radio";

interface SingleRegisterationProps extends GLFormComponentProps, RouteComponentProps<any> {
    onCancel: () => void;
    setStudentInfo: (child: any[], invitationChannel: InvitationChannel) => void;
    updateModifiedStatus: (modificationStatus: boolean) => void;
    availableLicenseCount: number;
    stepTrigger:RegistrationStepTriggerType;
    resetStepTrigger: () => void;
    regionPhoneCountryCode?: string;
    isPhoneEnabled: boolean;
}

export const SingleRegisteration = withRouter((GLForm.create()(
    (props: SingleRegisterationProps & RouteComponentProps) => {
    const { setStudentInfo, onCancel, regionPhoneCountryCode, match: { params: { classId } }, isPhoneEnabled } = props;
    const [birthday, setBirthday] = useState(null);
    const [isVisible, setVisible] = useState(true);
    const [isValidDay, setIsValidDay] = useState(true);
    const [mask, setMask] = useState(langsDateMasks[LanguageDateFormat[GLGlobal.intl.locale]]);
    const [invitationChannel, setInvitationChannel] = useState(InvitationChannel.Email);
    const datePickerRef = useRef(null);
    const isPageModified = useRef(false);

    useEffect(() => {
        birthday && setBirthday(moment(birthday, LanguageDateFormat[GLGlobal.intl.locale]).toDate());
        birthday && setVisible(false);
        setMask(langsDateMasks[LanguageDateFormat[GLGlobal.intl.locale]]);
    }, [GLGlobal.intl.locale]);

    useEffect(() => {
    if(props.stepTrigger === RegistrationStepTriggerType.Single){
        handleSubmit();
    }
    }, [props.stepTrigger]);

    const renderFormItem = FormHelper.renderFormItem;
    const form = props.form;
    const dateFormat: string = LanguageDateFormat[GLGlobal.intl.locale].toLowerCase().replace("mm", "MM");
    const placeholder = LanguageDateFormat[GLGlobal.intl.locale];

    const handleSubmit = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                setStudentInfo(getFormValues(), invitationChannel);
            }
            else
            {
                props.resetStepTrigger();
            }
        });
    };

    const getFormValues = () => {
        let { name, englishName, gender, parentEmail, parentPhoneNumber } = props.form.getFieldsValue();
        let phoneCountryCode = null;

        if (parentEmail === "") {
            parentEmail = null;
        }

        if (parentPhoneNumber) {
            parentPhoneNumber = regionPhoneCountryCode + parentPhoneNumber.toString();
            phoneCountryCode  = regionPhoneCountryCode;
        }

        gender = gender == Gender.None ? null : (gender == Gender.Male ? true : false);

        return [
            {
                studentId: generateGuid(),
                name,
                englishName,
                gender,
                parentEmail,
                parentPhoneNumber,
                phoneCountryCode,
                invitationChannel,
                birthday: birthday && moment(birthday).format("YYYY-MM-DD")
            }
        ];
    }

    const isFutureDate = (date: Date | moment.Moment) => {
        return moment(date).isAfter(new Date(), "day");
    };

    const handleDayChange = (date: Date): void => {
        onformValueChange();
        if (date) {
            const isValidDay = date && typeof date !== "undefined" && !isFutureDate(date);
            isValidDay && setBirthday(moment(date).toDate());
            isValidDay && setVisible(false);
        } else {
            setBirthday(date);
            setVisible(true);
        }
    };

    const isDateValid = (date: any) => {
        if (date === "" || typeof date === "undefined" || date === null) {
            return false;
        }
        return true;
    };

    const showDayPicker = (): void => {
        datePickerRef.current && datePickerRef.current.setOpen(true);
    };

    const renderBirthdayLabel = (): React.ReactNode => {
        return <span>{fmtMsg({ id: SchoolLocale.StudetnBirthday })}
            <Tooltip title={LanguageDateFormat[GLGlobal.intl.locale]}><Icon className="add-child__info" type="info-circle"></Icon></Tooltip>
        </span>
    };

    const onformValueChange = () => {
        if (!isPageModified.current) {
            isPageModified.current = true;
            props.updateModifiedStatus(true);
        }
    }

    const isNextDisabled = () => {
        const name = form.getFieldValue(StudentDetailsModelProps.name);
        const englishName = form.getFieldValue(StudentDetailsModelProps.englishName);
        const { parentPhoneNumber } = props.form.getFieldsValue();

        return props.availableLicenseCount === 0 
                || (parentPhoneNumber && (!props.regionPhoneCountryCode || !props.regionPhoneCountryCode.length))
                || (parentPhoneNumber && !isNumeric(parentPhoneNumber))
                || !isPageModified.current 
                || !name
                || name.length == 0
                || !englishName
                || englishName.length == 0
    }

    const updateInvitationChannel = (channel: InvitationChannel) => {
        if (channel === InvitationChannel.Email) {
            props.form.setFieldsValue({[StudentDetailsModelProps.parentPhoneNumber]: undefined})
        } else {
            props.form.setFieldsValue({[StudentDetailsModelProps.parentEmail]: undefined})
        }
        setInvitationChannel(channel);
    }

    const getPhoneFormItem = () => {
        return isPhoneEnabled 
        ? <Form.Item label={
            <div>
                <Radio value={InvitationChannel.Phone}/>
                <span onClick={() => updateInvitationChannel(InvitationChannel.Phone)} className="ant-form-item-no-colon">
                    {fmtMsg({id: SchoolLocale.StudentRegistrationPhoneLabel })}
                </span>
            </div> } 
            colon={false}>
            {
                form.getFieldDecorator(
                    StudentDetailsModelProps.parentPhoneNumber,
                    {
                        rules: [{validator: (rule: any, value: string, callback: (string?) => void) => {
                            if (value && !isNumeric(value)) {
                                callback(fmtMsg({ id: SchoolLocale.StudentRegistrationPhoneValidationMsg }));
                                return;
                            }
                            callback();
                        }}],
                    })(<RegistrationPhoneComponent maxLength={20} code={regionPhoneCountryCode} disabled={invitationChannel !== InvitationChannel.Phone}/>)
            }
            </Form.Item> 
        : null;
    }

    const renderRegistrationForm = () => {
        return <>
            <GLForm layout="horizontal" className="s-reg" form={form} onChange={onformValueChange}>
                <FormItemsLayout style={{ margin: "auto" }}>
                    {renderFormItem(form, SchoolLocale.StudentName, StudentDetailsModelProps.name, <Input />, null, true)}
                    {renderFormItem(form, SchoolLocale.StudentEnName, StudentDetailsModelProps.englishName, <Input />, null, true)}
                    <Form.Item
                        label={renderBirthdayLabel()}
                        colon={false}
                        required={false}
                        help={
                            isValidDay
                                ? ""
                                : fmtMsg({ id: SchoolLocale.StudetnBirthday })
                        }
                    >
                        <DatePicker
                            locale={GLGlobal.intl.locale}
                            dateFormat={dateFormat}
                            selected={birthday}
                            isClearable
                            onChange={handleDayChange}
                            disabledKeyboardNavigation
                            // customInput={<MaskedTextInput
                            //     type="text"
                            //     mask={mask}
                            //     className="ant-input ant-calendar-picker-input"
                            // />}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            popperPlacement="top-start"
                            showPopperArrow={false}
                            maxDate={new Date()}
                            placeholderText={placeholder}
                            ref={c => datePickerRef.current = c}
                            popperModifiers={{
                                // offset: {
                                //     enabled: true,
                                //     offset: "5px, 10px"
                                // },
                                preventOverflow: {
                                    enabled: true,
                                    escapeWithReference: true,
                                    boundariesElement: "scrollParent",
                                    padding: {
                                        top: 100
                                      }
                                }
                                // flip: {
                                //     enabled: true,
                                //     flipVariations: true,
                                //     boundariesElement: "scrollParent",
                                //     padding: {
                                //         top: 100
                                //     }
                                // }
                            }}
                        ></DatePicker>

                        <Icon
                            onClick={showDayPicker}
                            type="calendar"
                            className={isVisible + " anticon anticon-calendar ant-calendar-picker-icon"}
                        />
                    </Form.Item>
                    {renderFormItem(form, SchoolLocale.StudentGender, StudentDetailsModelProps.gender, 
                        <Select dropdownClassName="gender-select">
                            <Select.Option key={Gender.None} value={Gender.None}>
                            </Select.Option>
                            <Select.Option key={Gender.Male} value={Gender.Male}>
                                {fmtMsg({ id: SchoolLocale.StudentRegistrationStudentMale })}
                            </Select.Option>
                            <Select.Option key={Gender.Female} value={Gender.Female}>
                                {fmtMsg({ id: SchoolLocale.StudentRegistrationStudentFemale })}
                            </Select.Option>
                        </Select>,
                        Gender.None,
                        false
                    )}
                    <Radio.Group value={invitationChannel} onChange={(e: RadioChangeEvent) => {updateInvitationChannel(e.target.value); e.stopPropagation();}} className="s-reg__channel">
                        <Form.Item label={ isPhoneEnabled 
                                ? <div>
                                    <Radio value={InvitationChannel.Email}/>
                                    <span className="ant-form-item-no-colon" onClick={() => updateInvitationChannel(InvitationChannel.Email)}> 
                                        {fmtMsg({id: SchoolLocale.StudentParentEmail})} 
                                    </span>
                                  </div>
                                : fmtMsg({id: SchoolLocale.StudentParentEmail})}
                            colon={false}>
                        {
                            form.getFieldDecorator(
                                StudentDetailsModelProps.parentEmail,
                                {
                                    rules: [
                                        {
                                            validator: (
                                                rule: any,
                                                value: string,
                                                callback: (string?) => void
                                            ) => {
                                                inviteEmailValidation(
                                                    value,
                                                    callback
                                                );
                                                callback();
                                            },
                                        },
                                    ],
                                })(<Input type="email" disabled={invitationChannel !== InvitationChannel.Email}/>)
                        }
                        </Form.Item>
                        {getPhoneFormItem()}
                    </Radio.Group>
                </FormItemsLayout>
            </GLForm>
            <RegistrationNavigation onCancel={onCancel} isNextDisable={isNextDisabled()} onNextClick={handleSubmit} />
        </>
    }

    return <div>
        {renderRegistrationForm()}
    </div >
})));

const RegistrationPhoneComponent = forwardRef((props: any, ref: any) => {
    const { code, ...rest} = props;
    return (
        <div className="s-reg__phn-wrap">
            <div className="s-reg__phn">{code}</div>
            <InputNumber {...rest} ref={ref}/>
        </div>
    );
});

SingleRegisteration.defaultProps = {
    onCancel: () => { }
};
