import { GridInputDate, WijmoGrid } from "@app/components/grid";
import { useService } from "@app/hooks";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { IStudentRegistrationService, StudentDetailsModel, StudentDetailsModelProps } from "@app/service/school/student-registration";
import { BlobHelper, CommonHelper, DateHelper, fmtMsg, Gender, generateGuid, InvitationChannel, inviteEmailValidation, phoneValidation, RegistrationStepTriggerType, TYPES } from "@app/util";
import { Button, Radio, Upload } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import { ComparisonOperator, connect, FormHelper, GLGlobal, LanguageDateFormat, MatIcon, MessageHelper, NotificationType, StateType } from "gl-commonui";
import { Icon, Modal, Row } from "gl-commonui/lib/antd-min";
import { isEqual, isUndefined, isNumber, omit } from "lodash";
import * as moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import * as wjcCore from "wijmo/wijmo";
import { CollectionView } from "wijmo/wijmo";
import * as wjcGrid from "wijmo/wijmo.grid";
import { CellRangeEventArgs, HeadersVisibility, SelectionMode, KeyAction } from "wijmo/wijmo.grid";
import { GridCellTemplateType } from "wijmo/wijmo.interop.grid";
import { FlexGridCellTemplate, ICellTemplateContext } from "wijmo/wijmo.react.grid";
import XLSX from "xlsx";
import { RegistrationNavigation } from "./navigation";
import { getDate } from "@app/util/func";
import { RadioChangeEvent } from "antd/lib/radio";
import "./student-bulk-upload.less";
import { useStateWithRef } from "@app/hooks/useStateWithRef";

const { Grid, Column } = WijmoGrid;

//done because wijmo has issues 
let availableLicenseNumber: number = 0;

interface BulkUploadProps {
    onCancel: () => void;
    setStudentInfo: (child: any[], invitationChannel: InvitationChannel) => void;
    updateModifiedStatus: (modificationStatus: boolean) => void;
    availableLicenseCount: number;
    stepTrigger: RegistrationStepTriggerType;
    regionPhoneCountryCode?: string;
    isPhoneEnabled: boolean;
    resetStepTrigger: () => void;
}

const BulkUpload = (props: BulkUploadProps) => {
    const { onCancel, setStudentInfo, isPhoneEnabled } = props;
    const [dataSource, setDataSource] = useState<StudentDetailsModel[]>([]);
    const [isFileUploadDisable, setFileUploadStatus] = useState(false);
    const [currentAvailableLicenseCount, serCurrentAvailableLicenseCount] = useState(0);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const studentRegistrationService = useService<IStudentRegistrationService>(TYPES.IStudentRegistrationService);
    const isPageModified = useRef(false);

    const [flexGrid, setFlexGrid] = useState<wjcGrid.FlexGrid>();
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isAnyRowEdited, setIsAnyRowEdited] = useState<boolean>(false);
    const [isRowDirty, setIsRowDirty] = useState<boolean>(false);

    const [invitationChannel, setInivitationChannel, _, invitationChannelRef] = useStateWithRef(InvitationChannel.Email);

    useEffect(() => {
        //setting initialData
        setDataSource(getExtraRows(props.availableLicenseCount));
        serCurrentAvailableLicenseCount(props.availableLicenseCount);
        availableLicenseNumber = props.availableLicenseCount;
    }, []);

    useEffect(() => {
        if (props.stepTrigger === RegistrationStepTriggerType.Bulk) {
            if (!isValid || isAllNotDirty()) {
                props.resetStepTrigger();
            }
            else {
                onNextButtonClick();
            }
        }
    }, [props.stepTrigger]);


    useEffect(() => {
        const newLicenseAddedCount = props.availableLicenseCount - currentAvailableLicenseCount;
        if (newLicenseAddedCount > 0) {
            let updatedRecords: StudentDetailsModel[] = [];

            dataSource.forEach((item, index) => {
                updatedRecords.push(item);
            });
            let newRows = getExtraRows(newLicenseAddedCount);
            setDataSource(updatedRecords.concat(newRows));
            serCurrentAvailableLicenseCount(props.availableLicenseCount);
            availableLicenseNumber = props.availableLicenseCount;
        }
    }, [props.availableLicenseCount]);

    const getExtraRows = (count: number) => {
        let rowsData: StudentDetailsModel[] = [];

        for (let i = 0; i < count; i++) {
            let rowData: StudentDetailsModel = {
                studentId: null,
                name: null,
                englishName: null,
                birthday: null,
                gender: null,
                parentEmail: null,
                parentPhoneNumber: null,
                notes: null,
                isUploaded: false,
                isDirty: false,
            };
            rowsData.push(rowData);
        }

        return rowsData;
    }

    const triggerConfirmModal = (data, fileInfo, isFromupload, afterConfirm?: () => void, msg?: string) => {
        var isfromUploadFunc = isFromupload;
        var eventData = data;
        var file = fileInfo;
        Modal.confirm({
            title: msg ? msg : fmtMsg({ id: SchoolLocale.StudentRegistrationUploadConfirmationTitle }),
            content: fmtMsg({ id: SchoolLocale.StudentRegistrationUploadConfirmationMessage }),
            okText: fmtMsg({ id: GSAdminLocale.ModelButtonYes }),
            okType: "danger",
            cancelText: fmtMsg({ id: GSAdminLocale.ModelButtonNo }),
            onOk() {
                if (isfromUploadFunc) {
                    setUploadedData(eventData, file);
                } else {
                    setDataOnFileRemove();
                }
                typeof afterConfirm === 'function' && afterConfirm();
            },
            onCancel() { },
        });
    };

    const getError = (item: any, property: string): string => {
        // Unique validation error for all rows
        if (item.isDuplicate) {
            return fmtMsg(SchoolLocale.StudentDataUniqueMessage);
        }

        if (!item.isDirty) {
            return null;
        }

        // Property based validation
        switch (property) {
            case StudentDetailsModelProps.name:
                const name = item[StudentDetailsModelProps.name];
                if (!name || name.trim() === "") {
                    return FormHelper.ruleForRequire(SchoolLocale.StudentName).message;
                }
                if (name.length > 50) {
                    return FormHelper.ruleForCompareLength(SchoolLocale.StudentName, ComparisonOperator.LessOrEqualsThan, 50).validator(null, name);
                }
                break;
            case StudentDetailsModelProps.englishName:
                const englishName = item[StudentDetailsModelProps.englishName];
                if (!englishName || englishName.trim() === "") {
                    return FormHelper.ruleForRequire(SchoolLocale.StudentEnName).message;
                }
                if (englishName.length > 50) {
                    return FormHelper.ruleForCompareLength(SchoolLocale.StudentEnName, ComparisonOperator.LessOrEqualsThan, 50).validator(
                        null,
                        englishName
                    );
                }
                break;
            case StudentDetailsModelProps.gender:
                const gender = item[StudentDetailsModelProps.gender];
                if (gender &&
                    ![
                        fmtMsg({ id: SchoolLocale.StudentRegistrationStudentMale }),
                        fmtMsg({ id: SchoolLocale.StudentRegistrationStudentFemale }),
                    ].some((x) => x == gender)
                ) {
                    return fmtMsg({ id: SchoolLocale.StudentGenderValidation });
                }
                break;
            case StudentDetailsModelProps.birthday:
                const birthday = item[StudentDetailsModelProps.birthday];
                const format = getDateFormatTemplate();
                if (birthday && (!moment(birthday).isValid() || !moment(birthday, format).isValid())) {
                    return fmtMsg({ id: SchoolLocale.StudentBirthdayValidation }, { dateFormat: format });
                }
                break;
            case StudentDetailsModelProps.parentEmail:
                const parentEmail = item[StudentDetailsModelProps.parentEmail];
                if (parentEmail && parentEmail.length > 0) {
                    let message = null;
                    inviteEmailValidation(parentEmail, (msg: string) => !message ? message = msg : null);
                    return message;
                }
                break;
            case StudentDetailsModelProps.parentPhoneNumber:
                const parentPhoneNumber = item[StudentDetailsModelProps.parentPhoneNumber];
                if (parentPhoneNumber && parentPhoneNumber.toString().length > 0) {
                    let message = null;
                    phoneValidation(parentPhoneNumber, false, (msg: string) => !message ? message = msg : null);
                    return message;
                }

            default:
                return null;
        }
        return null;
    };

    const uniqueValidator = (flex: wjcGrid.FlexGrid) => {
        if (!flex.collectionView) {
            return null;
        }
        const data = getDirtyRows(flex.collectionView.sourceCollection) as any[];
        data.forEach((item) => {
            const isDuplicate = !!data.find((i) => {
                if (item === i) {
                    return false;
                }

                if(!item.birthday)
                {
                    item.birthday = null;
                }
                else
                {
                    item.birthday = new Date(new Date(item.birthday).toDateString());
                }
                
                if(!item.gender)
                {
                    item.gender = null
                }

                const left = item;
                const right = i;

                return invitationChannelRef.current === InvitationChannel.Phone ?
                isEqual(omit(left, ["isUploaded", "isDuplicate", "isDirty", "studentId", "notes", "parentEmail"]), omit(right, ["isUploaded", "isDuplicate", "isDirty", "studentId", "notes", "parentEmail"]))
                : isEqual(omit(left, ["isUploaded", "isDuplicate", "isDirty", "studentId", "parentPhoneNumber", "notes"]), omit(right, ["isUploaded", "isDuplicate", "isDirty", "studentId", "parentPhoneNumber", "notes"]))
            });
            if (isDuplicate) {
                item.isDuplicate = isDuplicate;
            } else {
                delete item.isDuplicate;
            }
        });
    };

    const getDateFormatTemplate = () => {
        return LanguageDateFormat[GLGlobal.intl.locale];
    };

    const getDateFormatTemplateForWijmo = () => {
        return getDateFormatTemplate().replace(/D/g, "d").replace(/Y/g, "y");
    };

    const getGenderNames = (gender: Gender): string => {
        const GenderNames = {
            [Gender.None]: "",
            [Gender.Male]: fmtMsg({ id: SchoolLocale.StudentRegistrationStudentMale }),
            [Gender.Female]: fmtMsg({ id: SchoolLocale.StudentRegistrationStudentFemale }),
        };
        return GenderNames[gender];
    };

    const genderColumnDataMap = new wjcGrid.DataMap(
        [Gender.None, Gender.Male, Gender.Female].map((gender) => ({ id: getGenderNames(gender), name: getGenderNames(gender) })),
        "id",
        "name"
    );

    const fileProps = {
        multiple: false,
        name: "file",
        accept: ".xls,.xlsx,.csv",
        fileList: [...fileList],
    };

    const beforeUpload = (file: RcFile) => {
        const extValid =
            file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type == "application/vnd.ms-excel" ||
            file.type == ".csv";
        if (!extValid) {
            MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.StudentDataFileType }));
            return false;
        }

        const isLt2MB = file.size / 1024 < 2048;
        if (!isLt2MB) {
            MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.StudentDataFileSize }, { fileSize: 2 }));
            return false;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
            var contents = processExcel(e.target.result);
            if (contents.msg != null) {
                MessageHelper.Message(NotificationType.Failed, contents.msg);
            } else {
                if (!isAllUploaded() && isPageModified.current) {
                    triggerConfirmModal(contents.result, file, true);
                } else {
                    setUploadedData(contents.result, file);
                }
                setIsRowDirty(true);
            }
        };
        reader.readAsBinaryString(file);
        return false;
    };

    const setUploadedData = (data: any[], file) => {
        if (validateUploadedData(data)) {
            setModificationStatus();
            let availableLicenseNumber = props.availableLicenseCount - data.length;
            let extraData = getExtraRows(availableLicenseNumber);
            let updatedData = data.concat(extraData);
            setDataSource(updatedData);
            setFileList([...fileList, file]);
            setFileUploadStatus(true);
        }
    };

    const setModificationStatus = () => {
        if (!isPageModified.current) {
            isPageModified.current = true;
            props.updateModifiedStatus(true);
        }
    };

    const validateUploadedData = (data: StudentDetailsModel[]): boolean => {
        var format = getDateFormatTemplate();
        var isValid = data.every((x) => {
            if (x.birthday) {

                if (isNumber(x.birthday)) {
                    let parsedDate = new Date(Math.round((x.birthday - 25569) * 86400 * 1000)).toISOString().substring(0, 10);
                    x.birthday = moment(parsedDate).toDate();
                }
                else {
                    x.birthday = moment(x.birthday).toDate();
                }

                return moment(x.birthday).isValid() && moment(x.birthday, format, true).isValid();
            } else {
                return true;
            }
        });

        if (!isValid) {
            MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.StudentBirthdayValidation }, { dateFormat: format }));
            return false;
        }

        data.forEach((x) => {
            x.isUploaded = true;
            x.isDirty = true;
            // x.birthday = removeLeadingZerosFromDate(x.birthday.toString());
        });

        return true;
    };

    const isAllUploaded = () => {
        return getDirtyRows(dataSource).every((x) => {
            if (!isUndefined(x.isUploaded)) {
                return x.isUploaded;
            }
            return false;
        });
    };
    const onFileRemove = (afterConfirm?: () => void, msg?: string, forceConfirm?: boolean) => {
        if ((!isAllUploaded() && isPageModified.current) || forceConfirm) {
            triggerConfirmModal(null, null, false, afterConfirm, msg);
        } else {
            setDataOnFileRemove();
            typeof afterConfirm === 'function' && afterConfirm();
        }
    };

    const setDataOnFileRemove = () => {
        setFileList([]); // Because for now we are uploading one file
        setFileUploadStatus(false);
        setDataSource(getExtraRows(props.availableLicenseCount));
    };

    const processExcel = (data) => {
        const workbook = XLSX.read(data, {
            type: "binary",
        });

        return to_json(workbook);
    };

    const to_json = (workbook) => {
        const resultModel = { result: [], msg: null };

        if (workbook.SheetNames.length > 1) {
            resultModel.msg = fmtMsg({ id: SchoolLocale.StudentDataFile });
            return resultModel;
        }

        const head = [
            StudentDetailsModelProps.name,
            StudentDetailsModelProps.englishName,
            StudentDetailsModelProps.birthday,
            StudentDetailsModelProps.gender,
        ];

        head.push(invitationChannel === InvitationChannel.Email ? StudentDetailsModelProps.parentEmail : StudentDetailsModelProps.parentPhoneNumber )
        const sheetToConvert = workbook.Sheets[workbook.SheetNames[0]];
        const roa = XLSX.utils.sheet_to_json(sheetToConvert, {
            header: head,
            defval: ""
        });
        if (roa.length) resultModel.result = roa;

        let dataLimit = props.availableLicenseCount;
        if (resultModel.result.length - 1 > dataLimit) {
            resultModel.result = [];
            resultModel.msg = fmtMsg({ id: SchoolLocale.StudentDataFileLoad }, { dataSize: dataLimit });
            return resultModel;
        } else if (resultModel.result.length > 0) {
            resultModel.result.splice(0, 1);
        }
        
        resultModel.result = resultModel.result.map(m => {
            if (m.parentPhoneNumber !== null && m.parentPhoneNumber !== undefined) {
                m.parentPhoneNumber = m.parentPhoneNumber.toString();
            }
            return m;
        });
        return resultModel;
    };

    const onNextButtonClick = () => {
        const data = getGridData();
        // if (data.length === 0) {
        //     MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.StudentRegistrationBulkUploadEmptyValidationMsg }));
        //     return null;
        // }

        const studentDetails = [];
        data.forEach((stu) => {
            const phone = !stu.parentPhoneNumber || stu.parentPhoneNumber === "" ? null : `${props.regionPhoneCountryCode}${stu.parentPhoneNumber}`;
            studentDetails.push({
                studentId: generateGuid(),
                name: stu.name,
                englishName: stu.englishName,
                parentEmail: isUndefined(stu.parentEmail) ? null : stu.parentEmail === "" ? null : stu.parentEmail,
                parentPhoneNumber: phone,
                phoneCountryCode: phone ? props.regionPhoneCountryCode : null,
                invitationChannel,
                gender: !stu.gender ? null : (stu.gender === fmtMsg({ id: SchoolLocale.StudentRegistrationStudentMale }) ? true : false),
                birthday: stu.birthday && moment(stu.birthday).format("YYYY-MM-DD"),
            });
        });

        setStudentInfo(studentDetails, invitationChannel);
    };

    const getGridData = (): any[] => {
        if (flexGrid && flexGrid.collectionView) {
            return getDirtyRows(flexGrid.collectionView.sourceCollection);
        }
        return [];
    };

    const downloadFormatFile = () => {
        setLoading(true);
        studentRegistrationService
            .downloadFormat(GLGlobal.intl.locale, invitationChannel)
            .then((data) => {
                CommonHelper.isMobile() ? BlobHelper.saveFileOnMobile(data) : BlobHelper.saveFile(data);
                setLoading(false);
            })
            .catch((er) => {
                setLoading(false);
            });
    };

    const cellEditEnded = (flex: wjcGrid.FlexGrid): void => {
        uniqueValidator(flex);
        setModificationStatus();
    };

    const cellEditEnding = (flex: wjcGrid.FlexGrid, rng: any, data?: any): void => {
        if (!isUndefined(data.isDirty) && !data.isDirty) {
            data.isDirty = true;
        }
    };

    const pastingCell = (grid: wjcGrid.FlexGrid, event: CellRangeEventArgs) => {
        if (grid.cells === event.panel && grid.columns[event.col].binding === StudentDetailsModelProps.birthday) {
            grid.setCellData(event.row ,event.col, moment(getDate(event.data)).toDate());
        }
    }

    const pasting = (grid: wjcGrid.FlexGrid, event: CellRangeEventArgs) => {
        const clipboardData = event.data.split("\n") as string[];
        const clipboardDataLength = clipboardData[clipboardData.length - 1] === "" ? clipboardData.length - 1 : clipboardData.length;
        if (clipboardDataLength === 0) {
            //stop flexgrid from pasting data
            event.cancel = true;
        }

        // const totalLength = getDirtyRows(grid.collectionView.sourceCollection).length + clipboardDataLength;
        let dataLimit = availableLicenseNumber;
        // if (totalLength > dataLimit) {
        //     MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.StudentDataFileLoad }, { dataSize: dataLimit }));
        //     //stop flexgrid from pasting data
        //     event.cancel = true;
        // }
        for (let i = event.row; i < clipboardDataLength + event.row; i++) {
            if (i === dataLimit) {
                break;
            }
            grid.collectionView.sourceCollection[i].isDirty = true;
        }

        setIsAnyRowEdited(true);
        setIsRowDirty(true);
    };

    const pasted = (flex: wjcGrid.FlexGrid, cellEvent): void => {
        uniqueValidator(flex);
        setModificationStatus();
    };

    const getDirtyRows = (data: StudentDetailsModel[]) => {
        return data.filter(x => x.isDirty);
    }

    const isAllNotDirty = () => {
        const result = dataSource.every(x => !x.isDirty);
        if (!result && !isAnyRowEdited) {
            setIsAnyRowEdited(true);
            setIsRowDirty(true);
        }
        
        return result;
    }

    const onRowReset = (context: ICellTemplateContext) => {
        // const item = context.item as StudentDetailsModel;
        const flex = context.row.grid;
        const rowIndex = context.row.index;
        const collectionView = context.row.grid.collectionView as CollectionView;
        const sourceCollection = collectionView.sourceCollection as StudentDetailsModel[];

        const newRowData = getExtraRows(1)[0];

        let updatedRecords: StudentDetailsModel[] = [];
        sourceCollection.forEach((item, index) => {
            if (index === rowIndex) {
                updatedRecords.push(newRowData)
            }
            else {
                updatedRecords.push(item)
            }
        });

        if (isFileUploadDisable && updatedRecords.every(x => !x.isDirty)) {
            setDataOnFileRemove();
        }
        else {
            setDataSource(updatedRecords);
            uniqueValidator(flex);
            setModificationStatus();
        }
        if (updatedRecords.every(x => x.isDirty == false)) {
            setIsRowDirty(false);
            setIsAnyRowEdited(false);
        }
    }

    const onInviteChannelChange = (e: RadioChangeEvent) => {
        const isAnyFieldHaveValue = dataSource.some(s => s.isDirty);
        onFileRemove(() => setInivitationChannel(e.target.value), fmtMsg({ id: SchoolLocale.StudentRegistrationBulkRegisterChangeCfm }), isAnyFieldHaveValue);
    }

    const renderStudentBulkUpload = () => {
        return (
            <>
                {/* <Spin spinning={loading}></Spin> */}
                {isPhoneEnabled && <div className="reg-type-selection-section">
                    <label className="man-reg__radio-grp">
                        {fmtMsg({
                            id:
                                SchoolLocale.StudentRegistrationBulkRegisterUsing
                        })}
                        :&nbsp;
                    </label>
                    <Radio.Group
                        value={invitationChannel}
                        onChange={onInviteChannelChange}
                    >
                        <Radio value={InvitationChannel.Email}>
                            {fmtMsg({
                                id:
                                    SchoolLocale.StudentRegistrationBulkRegisterUsingEmail
                            })}
                        </Radio>
                        <Radio value={InvitationChannel.Phone}>
                            {fmtMsg({
                                id:
                                    SchoolLocale.StudentRegistrationBulkRegisterUsingPhone
                            })}
                        </Radio>
                    </Radio.Group>
                </div>}
                <div className="student-bulk-upload-container">
                    <Row>
                        <div className="format-text">
                            {fmtMsg({
                                id:
                                    SchoolLocale.StudentRegistrationUploadSupportedFormat
                            })}{" "}
                            <a
                                onClick={downloadFormatFile}
                                href="javascript:void(0)"
                            >
                                {fmtMsg({
                                    id:
                                        SchoolLocale.StudentRegistrationUploadFormatLinkText
                                })}
                            </a>
                        </div>
                    </Row>
                    <Row>
                        <Upload
                            {...fileProps}
                            beforeUpload={beforeUpload}
                            onRemove={() => onFileRemove()}
                            disabled={isFileUploadDisable}
                        >
                            <Button>
                                <Icon type="upload" />{" "}
                                {fmtMsg({
                                    id:
                                        SchoolLocale.StudentRegistrationUploadButtonText
                                })}
                            </Button>
                        </Upload>
                        <span className="upload-note">
                            {" "}
                            {fmtMsg({
                                id: SchoolLocale.StudentRegistrationUploadNote
                            })}
                        </span>
                    </Row>
                    {/* <Row className="remaining-licenses">
                        <span>{fmtMsg({ id: SchoolLocale.StudentRegistrationRemainingLicensesLabel })}</span>{" "}
                        <span>({props.availableLicenseCount})</span>
                    </Row> */}
                    <Grid
                        itemsSource={dataSource}
                        // allowAddNew
                        // allowDelete
                        selectionMode={SelectionMode.CellRange}
                        headersVisibility={HeadersVisibility.All}
                        getError={getError}
                        allowSorting={false}
                        pagination={false}
                        cellEditEnded={cellEditEnded}
                        cellEditEnding={cellEditEnding}
                        loadedRows={uniqueValidator}
                        pasted={pasted}
                        pasting={pasting}
                        validateEdits={false}
                        keyActionTab={KeyAction.Cycle}
                        initialized={grid => setFlexGrid(grid)}
                        valid={isValid => setIsValid(isValid)}
                        pastingCell={pastingCell}
                    >
                        <FlexGridCellTemplate
                            cellType={GridCellTemplateType.RowHeader}
                            template={(context: ICellTemplateContext) => {
                                const item = context.item;
                                return item && item.isDirty ? (
                                    <a
                                        title={fmtMsg({
                                            id:
                                                SchoolLocale.StudentRegistrationResetRowTitle
                                        })}
                                        href="javascript:void(0)"
                                        onClick={() => onRowReset(context)}
                                    >
                                        <MatIcon type="undo" />
                                    </a>
                                ) : null;
                            }}
                        />
                        <Column
                            binding={StudentDetailsModelProps.name}
                            header={fmtMsg({ id: SchoolLocale.StudentName })}
                            isReadOnly={false}
                        />
                        <Column
                            binding={StudentDetailsModelProps.englishName}
                            header={fmtMsg({ id: SchoolLocale.StudentEnName })}
                            isReadOnly={false}
                        />
                        <Column
                            binding={StudentDetailsModelProps.birthday}
                            header={fmtMsg({
                                id: SchoolLocale.StudetnBirthday
                            })}
                            isReadOnly={false}
                            render={value => {
                                if (!isUndefined(value)) {
                                    return DateHelper.toLocalDate(value);
                                }
                                return null;
                            }}
                            dataType={wjcCore.DataType.Date}
                            renderEditor={context => {
                                return (
                                    <GridInputDate
                                        context={context}
                                        format={getDateFormatTemplateForWijmo()}
                                        max={new Date()}
                                    />
                                );
                            }}
                            minWidth={150}
                            maxWidth={150}
                            isRequired={false}
                        />
                        <Column
                            binding={StudentDetailsModelProps.gender}
                            header={fmtMsg({ id: SchoolLocale.StudentGender })}
                            isReadOnly={false}
                            dataMap={genderColumnDataMap}
                            minWidth={150}
                            maxWidth={150}
                        />
                        <Column
                            binding={StudentDetailsModelProps.parentEmail}
                            header={fmtMsg({
                                id: SchoolLocale.StudentParentEmail
                            })}
                            isReadOnly={false}
                            isRequired={false}
                            visible={
                                invitationChannel === InvitationChannel.Email
                            }
                        />
                        <Column
                            binding={StudentDetailsModelProps.parentPhoneNumber}
                            header={fmtMsg({
                                id:
                                    SchoolLocale.StudentRegistrationSuggestionCountryCodeGridCol
                            })}
                            isReadOnly={true}
                            render={() => {
                                return props.regionPhoneCountryCode;
                            }}
                            minWidth={150}
                            maxWidth={150}
                            visible={
                                invitationChannel === InvitationChannel.Phone
                            }
                            align="right"
                        />
                        <Column
                            binding={StudentDetailsModelProps.parentPhoneNumber}
                            header={fmtMsg({
                                id:
                                    SchoolLocale.StudentRegistrationSuggestionParentPhoneGridCol
                            })}
                            isReadOnly={false}
                            isRequired={false}
                            minWidth={200}
                            maxWidth={200}
                            visible={
                                invitationChannel === InvitationChannel.Phone
                            }
                        />
                    </Grid>
                    <Row>
                        <span>
                            {fmtMsg({
                                id:
                                    invitationChannel ===
                                    InvitationChannel.Phone
                                        ? SchoolLocale.StudentRegistrationParentInvitationPhoneNote
                                        : SchoolLocale.StudentRegistrationParentInvitationNote
                            })}
                        </span>
                    </Row>
                    <Row>
                        <RegistrationNavigation
                            onCancel={onCancel}
                            isNextDisable={
                                !isValid || isAllNotDirty() || !isAnyRowEdited || !isRowDirty
                            }
                            onNextClick={onNextButtonClick}
                        />
                    </Row>
                </div>
            </>
        );
    };
    return renderStudentBulkUpload();
};

export const StudentBulkUpload = connect(({ region: { model } }: StateType) => ({region: model}), {})(BulkUpload);

StudentBulkUpload.defaultProps = {
    onCancel: () => { }
};
