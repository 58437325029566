import React, { useState, useRef, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { GLRouteComponentProps } from "gl-commonui";
import { Button, Radio, Modal, Icon } from "antd-min";
import { SingleRegisteration } from "./manual-registration-components/single-registration";
import { ManualRegisterationSuggestions } from "./manual-registration-components/manual-registration-suggestions";
import { GenerateManualInvite } from "./manual-registration-components/generate-manual-invite";
import { fmtMsg, ManualRegistrationPageStep, subscriptionTypeUsage, ClassHelper, RegistrationStepTriggerType, ManualRegistrationType, TYPES, isInternalUser, InvitationChannel, isInternalUserWithoutAM } from "@app/util";
import { StudentBulkUpload } from "./manual-registration-components/student-bulk-upload";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { ClassesModel, ISchoolClassService, CurriculumType, SubscriptionType } from "@app/service/class";
import { StudentRegistrationModel } from "@app/service/school/student-registration";
import { CommonSectionIncModal } from "../../class/licenceComponents/modals/CommonSectionIncModal";
import { SchoolModel } from "@app/service/schools";
import { IRegionService, RegionModel } from "@app/service/admin/regions";
import { LicenseModel } from "../../class";
import { isUndefined } from "lodash";
import "../../class/licenceComponents/subscription.less"
import { useService } from "@app/hooks";

interface ManualRegisterationProps extends LicenseModel, GLRouteComponentProps {
    total: number;
    schoolClass: ClassesModel;
    school: SchoolModel;
    onCancel: () => void;
    passModificationStatus: (modificationStatus: boolean) => void;
    passPageStep: (pageStep: ManualRegistrationPageStep) => void;
    selectedPageStep: ManualRegistrationPageStep;
    hasLicenceHistory: boolean;
    match: any;
    futureAnnualOuter: boolean;
    regionInfo: RegionModel;
    classRoles: any;
    updateLicense: (d: { schoolId: string, classId: string, data: any }) => void;
    students?: any;
    updateLicenseCounts: (d: { schoolId: string, classId: string, data: any, schoolClassInfo: ClassesModel }) => void;
    stepTrigger: RegistrationStepTriggerType;
    passRegistrationTypeToParent: (manualRegistrationType: ManualRegistrationType) => void;
    resetStepTrigger: () => void;
}


export const ManualRegisteration = withRouter((props: ManualRegisterationProps & RouteComponentProps) => {
    const { onCancel, passModificationStatus, selectedPageStep, match: { params: { regionId }} } = props;

    const [registrationType, setRegistrationType] = useState<ManualRegistrationType>(ManualRegistrationType.Single);
    //const [currentStep, setCurrentStep] = useState(ManualRegistrationPageStep.Registration);
    const [studentDetails, setStudentDetails] = useState<StudentRegistrationModel[]>([]);
    const [finalStudentDetails, setFinalStudentDetails] = useState([]);
    const [addLicenseVisibility, setAddLicenseVisibility] = useState(false);
    const isChildModified = useRef(false);
    const [licenseUpdateInfo, setLicenseUpdateInfo] = useState<any>(null);
    const [accessibleClassIds, setAccessibleClassIds] = useState<string[]>([]);
    const [countryPhoneCode, setCountryPhoneCode] = useState("");
    const [isPhoneEnabled, setIsPhoneEnabled] = useState(false);
    const schoolClassService = useService<ISchoolClassService>(TYPES.ISchoolClassService);
    const regionService = useService<IRegionService>(TYPES.IRegionService);
    const invitationChannelRef = useRef(null);

    const stepOneResult = (studentInfo: StudentRegistrationModel[], invitationChannel: InvitationChannel) => {
        if (!!studentInfo) {
            invitationChannelRef.current = invitationChannel;
            setStudentDetails(studentInfo);
            //setCurrentStep(ManualRegistrationPageStep.Suggestion);
            props.passPageStep(ManualRegistrationPageStep.Suggestion);
        }
    }

    const stepTwoToOne = () => {
        props.passPageStep(ManualRegistrationPageStep.Registration);
        //  setCurrentStep(ManualRegistrationPageStep.Registration);
    }

    const stepTwoResult = (finalDetails: any[]) => {
        if (!!finalDetails) {
            setFinalStudentDetails(finalDetails);
            //setCurrentStep(ManualRegistrationPageStep.Invitation);
            props.passPageStep(ManualRegistrationPageStep.Invitation);
        }
    }

    useEffect(()=>{
        // get accessible class in case of external user only and account manager
        if(!isInternalUserWithoutAM()){
            schoolClassService.getAccessibleClassIds().then(ids=>{
                setAccessibleClassIds(ids);
            });
        }
        regionService.getRegionPhoneRegistrationSetting(regionId).then(isEnabled => setIsPhoneEnabled(isEnabled));
        regionService.getRegionCountryPhoneCode(regionId).then((code) => setCountryPhoneCode(code));
    }, []);

    const getRequiredView = () => {

        var requiredView = { title: "", content: <div></div> };

        switch (selectedPageStep) {
            // case ManualRegistrationPageStep.Registration:
            //     requiredView = {
            //         title: 'First',
            //         content: <div>{registrationType === ManualRegistrationType.Single
            //             ? <div className="s-reg-container"><SingleRegisteration setStudentInfo={stepOneResult} onCancel={onCancel} updateModifiedStatus={setModificationStatus} /></div>
            //             : <StudentBulkUpload setStudentInfo={stepOneResult} onCancel={onCancel} updateModifiedStatus={setModificationStatus} />}
            //         </div>
            //     };
            //     break;
            case ManualRegistrationPageStep.Suggestion:
                requiredView = {
                    title: 'Second',
                    content: <div>
                        <ManualRegisterationSuggestions licenseUpdateInfo={licenseUpdateInfo} regionId={regionId} 
                            studentImportedData={studentDetails} updateStudentFinalInfo={stepTwoResult} onCancel={onCancel} 
                            enrolledStudentCount={props.total} schoolClass={props.schoolClass} onPrev={stepTwoToOne} 
                            accessibleClassIds ={accessibleClassIds} invitationChannel={invitationChannelRef.current}/>
                    </div>,
                };
                break;
            case ManualRegistrationPageStep.Invitation:
                requiredView = {
                    title: 'Last',
                    content: <div><GenerateManualInvite studentDetails={finalStudentDetails} schoolClass={props.schoolClass} onCancel={onCancel} school={props.school} history={props.history} isPhoneEnabled={isPhoneEnabled} match={props.match}/></div>,
                };
                break;
        }
        return requiredView;
    }

    const onLicenseChange = (newCount: { newDigitalCount: number, newTextbookCount: number, textbooknote: string, digitalnote: string, licensePlusStudentEdited: boolean, removedStudents: string[], noteType: SubscriptionType }, studentSubscriptionChanges: any[] = []) => {
        const { newDigitalCount, newTextbookCount, textbooknote, digitalnote, licensePlusStudentEdited, removedStudents, noteType } = newCount;
        const { match: { params }, schoolClass } = props;
        const schoolClassStatus = {  // This object is for license count change, must be present always even if not changed.
            "newStudentCount": newDigitalCount >= newTextbookCount ? newDigitalCount : newTextbookCount,
            newTextbookLicenses: newTextbookCount,
            newDigitalLicenses: newDigitalCount,
            "textbookLicenseEditNote": textbooknote,
            "digitalLicenseEditNote": digitalnote,
        }
        const data = { schoolClassStatus, studentSubscriptionChanges, removedStudents, licensePlusStudentEdited, noteType };
        const req = { schoolId: params.schoolId, classId: params.classId, data, schoolClassInfo: schoolClass };

        setLicenseUpdateInfo(data);
        props.updateLicenseCounts(req);
        // props.updateLicense(req);
    }

    const addLicense = () => {
        setAddLicenseVisibility(true);
    }

    const canEditLicense = () => {
        return ClassHelper.canEditLicense(props.classRoles, props.school.allowSchoolEditLicense) && !props.futureAnnualOuter;
    }

    const showSubscriptionAddSection = () => {
        if (typeof props.curriculumType !== "undefined" && props.counts && props.regionInfo && addLicenseVisibility) {
            return <CommonSectionIncModal
                onCancel={() =>
                    setAddLicenseVisibility(false)
                }
                onSave={d => {
                    onLicenseChange(d);
                    setAddLicenseVisibility(false);
                }}
                isFromRegistration={true}
                isDual={props.school.subscriptionTypeUsage === subscriptionTypeUsage.Dual}
                licenseCount={props.counts}
                visible={addLicenseVisibility}
                title={fmtMsg({ id: SchoolLocale.EditClassIncDigLicence })}
                regionInfo={props.regionInfo}
                canEditLicense={canEditLicense()}
                subType={props.subType}
                isLittleSeed={
                    props.curriculumType === CurriculumType.LittleSEED
                }
            />
        }
        else {
            return <></>;
        }
    }

    const onRegistrationTypeChange = (e) => {
        if (isChildModified.current) {
            triggerConfirmModal(e);
        }
        else {
            setRegistrationType(e.target.value);
            props.passRegistrationTypeToParent(e.target.value);
        }
    }

    const setModificationStatus = (modificationStatus) => {
        isChildModified.current = modificationStatus;
        passModificationStatus(modificationStatus);
    }

    const triggerConfirmModal = (e) => {
        var eventData = e;
        Modal.confirm({
            title: fmtMsg({ id: SchoolLocale.StudentRegistrationChangeViewConfirmTitle }),
            content: fmtMsg({ id: SchoolLocale.StudentRegistrationChangeViewConfirmMessage }),
            okText: fmtMsg({ id: GSAdminLocale.ModelButtonYes }),
            okType: "danger",
            cancelText: fmtMsg({ id: GSAdminLocale.ModelButtonNo }),
            onOk() {
                isChildModified.current = false;
                setRegistrationType(eventData.target.value);
                props.passRegistrationTypeToParent(eventData.target.value);
            },
            onCancel() { },
        });
    }

    const isContentHidden = () => {
        if (selectedPageStep !== ManualRegistrationPageStep.Registration) {
            if (registrationType === ManualRegistrationType.Bulk) {
                return "reg-bulk-hide";
            }
            return "reg-hide";
        }
        return "";
    }


    const getAvailableLicenseCount = () => {
        if (props.schoolClass.studentCount > 0 && !isUndefined(props.students)) {
            return props.schoolClass.studentCount - props.total;
        }
        else {
            return 0;
        }
    }

    return <div className="man-reg">
        {selectedPageStep == ManualRegistrationPageStep.Registration && <div>
            <div className="license-section">
                <label>{fmtMsg({ id: SchoolLocale.StudentRegistrationClassLicensesLabel })}:&nbsp;</label>
                <span>{fmtMsg({ id: SchoolLocale.StudentRegistrationTotalLicenses })}: {props.schoolClass.studentCount}</span>
                <span>{fmtMsg({ id: SchoolLocale.StudentRegistrationAvailableLicenses })}: {getAvailableLicenseCount()}</span>
                {props.hasLicenceHistory &&
                    <Button disabled={!canEditLicense()} onClick={addLicense}>
                        <Icon type="plus" />
                        {fmtMsg({ id: SchoolLocale.StudentRegistrationAddLicenses })}
                    </Button>}
            </div>

            <div className="reg-type-selection-section">
                <label className="man-reg__radio-grp">{fmtMsg({ id: SchoolLocale.StudentRegistrationNewStudentsLabel })}:&nbsp;</label>
                <Radio.Group value={registrationType} onChange={onRegistrationTypeChange}>
                    <Radio value={ManualRegistrationType.Single}>{fmtMsg({ id: SchoolLocale.StudentRegistrationManualTypeSingleText })}</Radio>
                    <Radio value={ManualRegistrationType.Bulk}>{fmtMsg({ id: SchoolLocale.StudentRegistrationManualTypeBulkText })}</Radio>
                </Radio.Group>
            </div>
        </div>}
        <div className="man-reg">
            <div className="man-reg__content-wrap">
                {selectedPageStep !== ManualRegistrationPageStep.Invitation && <div className={isContentHidden()}>{registrationType === ManualRegistrationType.Single
                    ? <div className="s-reg-container">
                        <SingleRegisteration 
                            resetStepTrigger={props.resetStepTrigger} 
                            stepTrigger={props.stepTrigger} 
                            setStudentInfo={stepOneResult} 
                            onCancel={onCancel} 
                            updateModifiedStatus={setModificationStatus} 
                            availableLicenseCount={getAvailableLicenseCount()} 
                            regionPhoneCountryCode={countryPhoneCode}
                            isPhoneEnabled={isPhoneEnabled}/></div>
                    : <StudentBulkUpload 
                        stepTrigger={props.stepTrigger} 
                        resetStepTrigger={props.resetStepTrigger} 
                        setStudentInfo={stepOneResult} 
                        onCancel={onCancel} 
                        updateModifiedStatus={setModificationStatus} 
                        availableLicenseCount={getAvailableLicenseCount()}
                        regionPhoneCountryCode={countryPhoneCode}
                        isPhoneEnabled={isPhoneEnabled}/>}
                </div>}
                {getRequiredView().content}
            </div>
        </div>

        {showSubscriptionAddSection()}
    </div>;
});

ManualRegisteration.defaultProps = {
    onCancel: () => { }
};

