import React, { useRef, Component, useEffect } from "react";
import { Spin, Form, Select, InputNumber } from "antd-min";
import { fmtMsg, InvitationChannel, onSubmit } from "@app/util";
import { SchoolLocale } from "@app/locales/localeid";
import { withRouter, FormHelper, NotificationType, MessageHelper, connect, GLForm, GLFormComponentProps, InvitationType, alignPop } from "gl-commonui";
import { RouteComponentProps } from "react-router";
import { initTemplates } from '@app/states/school/invitation';
import { downloadStudentInvitations } from '@app/states/school/invitation';
import { resetTemplate4Preview } from '@app/states/invitation-template';
import { SubmitBtns } from "@app/components/school/form-btns";
import { PreviewTemplate } from '../preview';
import { StateType } from "@app/states";

interface GenericInvitationProps extends RouteComponentProps {
    total: number;
    schoolClass: any;
    regionId: string;
    onClose: () => void;
    passModificationStatus: (modificationStatus: boolean) => void;
}

export const GenericInvitation = withRouter(connect(({ schoolClass: { list, total }, school: { current: { regionId } } }: StateType) => ({
    classes: list,
    total,
    regionId,
}), {}, ({ state: { classes, students } }) => ({
    schoolClass: classes[0] || {}
}))((props: GenericInvitationProps) => {
    const { match: { params }, total, schoolClass } = props;
    const isShowModal = newDigitalCnt(total, schoolClass) !== 0;

    useEffect(() => {
        return () => {
            flag = false;
        };
    }, []);

    return <StudentsInvited params={params}
        limit={newDigitalCnt(total, schoolClass)}
        close={() => props.onClose()}
        setModificationStatus={props.passModificationStatus}
    />
}));

var flag = false;
function newDigitalCnt(total, { licenseType, digitalLicense, studentCount }) {
    //  if (Number.isInteger(total) && licenseType === LicenseType[LicenseType.GrapeSEED]) {
    const newCnt = studentCount - total;
    if (newCnt <= 0) {
        if (!flag) {
            const msg = fmtMsg({ id: SchoolLocale.StudetnInviteFull });
            MessageHelper.Message(NotificationType.Failed, msg);
            flag = true;
        }
        return 0;
    }
    return newCnt;
    // }
    // return 0;
}
export interface StudentsInvitedFormProps {
    initTemplates?: (d) => void;
    close: () => void;
    handleInviteFormSubmit?: (values: any) => void;
    resetTemplate4Preview?: () => void;
    templates?: any[];
    model?: any;
    limit?: number;
    params?: any;
    loading: boolean;
    setModificationStatus: (modificationStatus: boolean) => void;
}
@connect(({ schoolClass: { model }, invitation: { templates, loading } }: StateType) => ({

    model,
    templates,
    loading
}), {
    initTemplates,
    downloadStudentInvitations,
    resetTemplate4Preview
}, ({ dispatch: { downloadStudentInvitations }, own: { params: { classId } } }) => ({
    handleInviteFormSubmit: (values) => {
        downloadStudentInvitations({ ...values, classId });
    }
}))
@GLForm.create()
class StudentsInvited extends Component<StudentsInvitedFormProps & GLFormComponentProps, { showPreviewModal, templateId4Preview }> {
    componentDidMount() {
        this.props.initTemplates({type: InvitationType.parent, invitationChannel: InvitationChannel.Email});
    }

    onPreview(showPreviewModal) {
        const { form, resetTemplate4Preview } = this.props;
        this.onformValueChange();
        form.validateFields((err, values) => {
            if (!err) {
                if (!showPreviewModal) {
                    resetTemplate4Preview();
                }
                this.setState({
                    showPreviewModal,
                    templateId4Preview: values.templateId
                });
            }
        });
    }

    onformValueChange = () => {
        this.props.setModificationStatus(true);
    }

    render() {
        const { form, close, handleInviteFormSubmit, templates, limit, loading } = this.props;
        const renderFormItem = FormHelper.renderFormItem;
        const showPreviewModal = this.state && this.state.showPreviewModal;
        const templateId4Preview = this.state && this.state.templateId4Preview;
        return (
            <Spin spinning={showPreviewModal || loading}>
                <GLForm form={form} onSubmit={onSubmit(this, handleInviteFormSubmit)} onChange={(e => this.onformValueChange())} className="genr-sec gl-modal">
                    {renderFormItem(form, SchoolLocale.StudetnInviteCnt, 'count', <InputNumber min={1} max={limit} precision={0} className="genr-sec__input" />, limit, true)}
                    {renderFormItem(form, SchoolLocale.InvitationTemplate, 'templateId', <TemplatesSelect templates={templates} />, null, true)}
                    <Form.Item className="genr-sec__actions">
                        <SubmitBtns
                            submitTitle={SchoolLocale.StudetnInviteDownload}
                            previewTitle={SchoolLocale.Preview}
                            onPreview={(e) => this.onPreview(true)}
                            onCancel={close}
                            isDisabled={limit === 0}
                        />
                    </Form.Item>
                </GLForm>
                {showPreviewModal && <PreviewTemplate
                    visible={showPreviewModal}
                    templateId4Preview={templateId4Preview}
                    onCancel={(e) => this.onPreview(false)}
                    isEmailTemplate={false}
                />}
            </Spin>
        );
    }
}

interface TemplatesSelectProps {
    templates: any[];
}
export class TemplatesSelect extends Component<TemplatesSelectProps> {
    // note here we are overriding value props so we can show placeholder
    render() {
        return (
            <Select placeholder={fmtMsg({ id: SchoolLocale.StudentTemplatePlaceholder })} loading={this.props.templates.length === 0} {...this.props} value={this.props.value || undefined} size='large' {...alignPop()} >
                {this.props.templates.map(template => <Select.Option key={template.id} value={template.id} >{template.name}</Select.Option>)}
            </Select >
        )
    }
}
