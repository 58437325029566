import React, { useEffect, useState, useRef } from "react";
import { fmtMsg, InvitationChannel, TYPES } from "@app/util";
import { Modal, Spin, Button, Row } from "antd-min";
import { useService } from "@app/hooks";
import { connect, StateType, formatRegionalUrl } from "gl-commonui";
import { SchoolLocale } from "@app/locales/localeid";
import { GLRichTextEditor } from "@app/components";
import {
    IInvitationService,
    emailInvitationsRequestModel
} from "@app/service/school/invitation";
import { sendInvitations } from "@app/states/school/invitation";
import { InviteBy } from "@app/page/school/student/registration/manual-registration-components/generate-manual-invite";
import "./parent-invitation.less";

interface ParentInvitationProps {
    templateId: string;
    classId: string;
    invitations: any[];
    visible: boolean;
    isProcessingEmail: boolean;
    invitingBy: InviteBy;
    sendInvitations: (value?: any) => void;
    onCancel?: (value?: any) => void;
}

interface templateModel {
    backgroundImage: string;
    name: string;
    title: string;
    text: string;
}

const ParentInvitationComp = (props: ParentInvitationProps) => {
    const [compLoading, setCompLoading] = useState(false);
    const invitationsService = useService<IInvitationService>(
        TYPES.IStudentInvitationService
    );
    const [emailButtonStatus, setEmailButtonStatus] = useState(true);
    const [invitationTemplate, setInvitationTemplate] = useState<
        templateModel
    >();
    const [emailHtml, setEmailHtml] = useState("<span></span>");
    const [email, setEmail] = useState("");
    const isSendingEmail = useRef(false);

    useEffect(() => {
        previewEmailTemplate();
    }, []);

    useEffect(() => {
        if (!props.isProcessingEmail && isSendingEmail.current) {
            props.onCancel(false);
        }
    }, [props.isProcessingEmail]);

    const previewEmailTemplate = () => {
        setCompLoading(true);
        invitationsService
            .previewParentInvitations(getRequiredData())
            .then(data => {
                if (data.invitationTemplate) {
                    setInvitationTemplate(data.invitationTemplate);
                    if (
                        data.invitationCodeHtmls &&
                        data.invitationCodeHtmls.length > 0
                    ) {
                        let firstRecord = data.invitationCodeHtmls[0];
                        setEmailHtml(firstRecord.invitationHtml);
                        setEmail(
                            props.invitingBy === InviteBy.Phone
                                ? firstRecord.parentPhoneNumber
                                : firstRecord.parentEmail
                        );
                        setEmailButtonStatus(false);
                    }
                }
                setCompLoading(false);
            })
            .catch(er => {
                setCompLoading(false);
            });
    };

    const getRequiredData = () => {
        let dataToSend: emailInvitationsRequestModel = {
            invitationTemplateId: props.templateId,
            invitations: props.invitations,
            invitationChannel: props.invitingBy === InviteBy.Phone ? InvitationChannel.Phone : InvitationChannel.Email,
            isReGenerateOrEmail: false
        };

        return dataToSend;
    };

    const sendEmail = () => {
        isSendingEmail.current = true;
        props.sendInvitations({
            emailData: getRequiredData(),
            withLoader: false
        });
    };

    const getInlineStyle = () => {
        return invitationTemplate && invitationTemplate.backgroundImage
            ? {
                  backgroundImage: `url(${invitationTemplate.backgroundImage})`,
                  backgroundSize: `auto auto`,
                  backgroundRepeat: "no-repeat"
              }
            : {};
    };

    return (
        <Modal
            title={fmtMsg({
                id: SchoolLocale.StudentRegistrationInviteEmailTitle
            })}
            destroyOnClose={true}
            footer={null}
            visible={props.visible}
            onCancel={e => props.onCancel(false)}
            className="parent-invitation"
        >
            <Spin spinning={compLoading || props.isProcessingEmail}>
                <Row>
                    <span className="title">
                        {fmtMsg({
                            id: SchoolLocale.StudentRegistrationEmailSubject
                        })}
                        :&nbsp;
                    </span>
                    {invitationTemplate && invitationTemplate.title
                        ? typeof invitationTemplate.title === 'string' ? formatRegionalUrl(invitationTemplate.title): invitationTemplate.title
                        : ""}
                </Row>
                <Row>
                    <span className="title">
                        {fmtMsg({
                            id: SchoolLocale.StudentRegistrationEmailTo
                        })}
                        :&nbsp;
                    </span>
                    {email}
                </Row>
                <div style={getInlineStyle()} className="editor">
                    <GLRichTextEditor readOnly={true} editorHtml={typeof emailHtml === 'string' ? formatRegionalUrl(emailHtml): emailHtml} />
                </div>
                <Button
                    className="email-button"
                    disabled={emailButtonStatus}
                    type="primary"
                    onClick={e => sendEmail()}
                >
                    {props.invitingBy === InviteBy.Phone
                        ? fmtMsg({
                              id:
                                  SchoolLocale.StudentRegistrationInvitePhoneButtonText
                          })
                        : fmtMsg({
                              id:
                                  SchoolLocale.StudentRegistrationInviteEmailButtonText
                          })}
                </Button>
            </Spin>
        </Modal>
    );
};

export const ParentInvitation = connect(
    ({ invitation: { isProcessingEmail } }: StateType) => ({
        isProcessingEmail
    }),
    {
        sendInvitations
    }
)(ParentInvitationComp);
