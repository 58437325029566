import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Form, InputNumber, Card, List, Input, Checkbox } from "antd-min";
import { fmtMsg } from "@app/util/func";
import { SchoolLocale } from "@app/locales/localeid";
import { GLLocale } from "gl-commonui";
import { RegionInfoExtended } from "../subscription";
import { StudentInformation } from "..";
import { SubscriptionType } from "@app/service/class";
import { CONSTS } from "@app/util/consts";
import { LicenseDisabled, RegionType } from "@app/util";
import { connect } from 'react-redux';
import { StateType } from '@app/states';
import { isNull } from "lodash";

interface CommonSectionIncModalProps {
    onSave: (newInfo: { newDigitalCount: number, newTextbookCount: number, textbooknote: string, digitalnote: string, licensePlusStudentEdited: boolean, removedStudents: string[], noteType: SubscriptionType }) => void;
    onCancel: () => void;
    isDual: boolean; // Whether class have dual subscription
    isLittleSeed?: boolean;
    subType: SubscriptionType;
    licenseCount: { sub: { newCnt: number, note: any }, license: { newCnt: number, note: any } };
    visible: boolean;
    title: string;
    regionInfo: RegionInfoExtended;
    canEditLicense: boolean;
    langLoaded?: string;
    isFromRegistration: boolean;
    disableFutureLicenseEdit?: LicenseDisabled;
};

export const CommonSectionIncModal = connect(({ intl: { langLoaded } }: StateType) => ({ langLoaded }))((props: CommonSectionIncModalProps) => {
    const { onSave, onCancel, isDual, isLittleSeed, licenseCount, visible, title, regionInfo, canEditLicense, subType, langLoaded, isFromRegistration, disableFutureLicenseEdit } = props;
    const regionType = regionInfo.id == process.env.jpRegionId ? RegionType.Japan : RegionType.Standard;

    const [increaseValue, setIncreaseValue] = useState(0);
    const [note, setNote] = useState("");

    useEffect(() => {
        if (isFromRegistration) {
            let noteInfo = "";

            if (isLittleSeed && subType === SubscriptionType.Digital) {
                noteInfo = isNull(licenseCount.license.note) ? "" : licenseCount.license.note;
            }
            else {
                noteInfo = isNull(licenseCount.sub.note) ? "" : licenseCount.sub.note;
            }

            setNote(noteInfo);
        }
    }, []);

    const saveInfo = () => {
        // if littleseed then only textbook will be set
        if (isLittleSeed) {
            onSave({
                newDigitalCount: 0,
                newTextbookCount: licenseCount.sub.newCnt + increaseValue,
                textbooknote: note,
                digitalnote: "",
                licensePlusStudentEdited: false,
                removedStudents: [],
                noteType: SubscriptionType.Textbook,
            });
        } else if (isDual) {
            //if class is dual then decrease from both
            onSave({
                newDigitalCount: licenseCount.sub.newCnt + increaseValue,
                newTextbookCount: licenseCount.sub.newCnt + increaseValue,
                textbooknote: subType === SubscriptionType.Digital ? "" : note,
                digitalnote: subType === SubscriptionType.Digital ? note : "",
                licensePlusStudentEdited: false,
                removedStudents: [],
                noteType: subType,
            });
        } else if (subType === SubscriptionType.Digital) {
            onSave({
                newDigitalCount: licenseCount.sub.newCnt + increaseValue,
                newTextbookCount: licenseCount.license.newCnt,
                textbooknote: "",
                digitalnote: note,
                licensePlusStudentEdited: false,
                removedStudents: [],
                noteType: SubscriptionType.Digital,
            });
        } else {
            onSave({
                newDigitalCount: licenseCount.license.newCnt,
                newTextbookCount: licenseCount.sub.newCnt + increaseValue,
                textbooknote: note,
                digitalnote: "",
                licensePlusStudentEdited: false,
                removedStudents: [],
                noteType: SubscriptionType.Textbook,
            });
        }
    }

    const showIncreaseNote = regionInfo && regionInfo.billForPartialMonthAdd && regionInfo.schoolClassInvoiceInfo.isCurrentInvoiceGenerated;
    const noteLengthError = fmtMsg({ id: GLLocale.FormCprLessOrEqualsThan }, { name: fmtMsg({ id: GLLocale.FormFormatLength }, { name: fmtMsg({ id: SchoolLocale.LicenseNote }).replace(':', "") }), value: 200 });
    const isDirty = increaseValue > 0;

    const noteError = note.length > 200;
    return <Modal
        destroyOnClose
        visible={visible}
        title={title}
        onCancel={onCancel}
        onOk={saveInfo}
        okText={fmtMsg({ id: isFromRegistration ? SchoolLocale.EditClassLicenseModalUpdateButton : SchoolLocale.EditClassLicenseModalSaveButton })}
        cancelText={fmtMsg({ id: GLLocale.Cancel })}
        okButtonProps={{ className: "sub-modal__btn sub-modal__btn--submit", disabled: !isDirty || (regionType === RegionType.Japan && !note.length) }}
        cancelButtonProps={{ className: "sub-modal__btn sub-modal__btn--cancel" }}
        className="sub-modal"
    >
        <Row type="flex">
            <Col className="sub-modal__ccount">{fmtMsg({ id: SchoolLocale.LicenseStudentCnt })}</Col>
            <Col>{licenseCount.sub.newCnt}</Col>
        </Row>
        <Form.Item
            label={fmtMsg({ id: SchoolLocale.EditClassIncBy })}
            required={true}
            validateStatus={"success"}
            help={null}
            className="sub-modal__input"
        >
            <InputNumber
                value={increaseValue}
                min={0}
                max={CONSTS.MAX_INTEGER_VALUE}
                precision={0}
                onChange={(value) => setIncreaseValue(value)} 
                disabled={disableFutureLicenseEdit && disableFutureLicenseEdit.isLicenseEditDisabled}/>
        </Form.Item>
        <Row type="flex">
            <Col className="sub-modal__ncount">{fmtMsg({ id: SchoolLocale.EditClassNewTotal })}:</Col>
            <Col>{licenseCount.sub.newCnt + increaseValue}</Col>
        </Row>

        <Form.Item
            validateStatus={noteError ? 'error' : 'success'}
            className={noteError ? 'sub-error-row' : 'sub-normal-row'}
            help={noteError ? noteLengthError : null}
            label={fmtMsg({ id: SchoolLocale.LicenseHistoryNotes })}
            required={regionType === RegionType.Japan}
        >
            <Input.TextArea rows={4} disabled={!canEditLicense} defaultValue={note} placeholder={fmtMsg({ id: (regionType === RegionType.Japan && langLoaded == 'en') ? SchoolLocale.LicenseIncreaseNotePlaceholderTextJapan : SchoolLocale.LicenseIncreaseNotePlaceholderText })} onChange={e => setNote(e.target.value)} />
        </Form.Item>
        {showIncreaseNote &&
            <div className="sub-modal__note">
                <span className="sub-modal__label">{fmtMsg(SchoolLocale.LicenseNote)} </span>
                <span>{fmtMsg({ id: SchoolLocale.EditClassIncNote })}</span>
            </div>
        }
    </Modal >

})

CommonSectionIncModal.defaultProps = {
    isFromRegistration: false
};
