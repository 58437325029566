import React, { useEffect, useState, useMemo } from "react";
import { initTemplates } from '@app/states/school/invitation';
import { setIsOnInvitationTab } from '@app/states/school/class';
import { StateType } from "@app/states";
import { TemplatesSelect } from "../generic-invitation";
import { GLForm, GLFormComponentProps, FormHelper, connect, InvitationType, GLUtil } from "gl-commonui";
import { RegistrationNavigation } from "./navigation";
import { Row } from "gl-commonui/lib/antd-min";
import { SchoolLocale } from "@app/locales/localeid";
import { resetTemplate4Preview } from '@app/states/invitation-template';
import { Button, Checkbox, Radio } from "antd";
import { downloadManualInvitations } from '@app/states/school/invitation';
import { ClassesModel, SchoolClassModel } from "@app/service/class";
import { fmtMsg, DateHelper, InvitationChannel } from "@app/util";
import { StudentDetailsModelProps, StudentRegistrationModel } from "@app/service/school/student-registration";
import { GLTable } from "@app/components/admin/gl-table";
import { PreviewTemplate } from "../../preview";
import { ParentInvitation } from "@app/components/school";
import { downloadInvitationsRequestModel, invitationCodeModel } from "@app/service/school/invitation";
import { PathConfig } from "@app/config/pathconfig";
import { SchoolModel } from "@app/service/schools";
import { RadioChangeEvent } from "antd/lib/radio";
import { History } from "history";
import { match } from "react-router";

interface GenerateManualInviteProps extends GLFormComponentProps {
    templates: any[];
    initTemplates: (args: {type: InvitationType}) => void;
    onCancel: () => void;
    setIsOnInvitationTab: (d) => void;
    resetTemplate4Preview?: () => void;
    downloadManualInvitations?: (values: any) => void;
    schoolClass: ClassesModel;
    model: SchoolClassModel;
    studentDetails: any;
    loading: boolean;
    school: SchoolModel;
    history: History;
    isPhoneEnabled: boolean;
    match: match;
}

export enum InviteBy {
    Email,
    Phone,
    Print
}

class ExistingRegisteredStudentsTable extends GLTable<StudentRegistrationModel> { };
class NewlyRegisteredStudentsTable extends GLTable<StudentRegistrationModel> { }

export const GenerateManualInvite = connect(({ schoolClass: { 
    model }, invitation: { templates, loading }, }: StateType) => ({
    model,
    templates,
    loading
}), {
    initTemplates,
    setIsOnInvitationTab,
    resetTemplate4Preview,
    downloadManualInvitations
})((GLForm.create()(React.memo((props: GenerateManualInviteProps) => {
    const { templates, initTemplates, onCancel, isPhoneEnabled, history } = props;
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState("");
    const [existingStudents, setExistingStudents] = useState<StudentRegistrationModel[]>([]);
    const [newlyAddedStudents, setNewlyAddedStudents] = useState<StudentRegistrationModel[]>([]);
    const [allToggleStatus, setAllToggleStatus] = useState(false);
    const [allToggleIndeterminateStatus, setAllToggleIndeterminateStatus] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [emailInvitations, setEmailInvitations] = useState<invitationCodeModel[]>([]);
    const [inviteParentBy, setInviteParentBy] = useState(InviteBy.Print);
    const renderFormItem = FormHelper.renderFormItem;
    const form = props.form;

    useEffect(() => {
        initTemplates({type: InvitationType.parent});

        if (props.studentDetails) {
            setExistingStudents(props.studentDetails.updatedStudents);

            let newlyAdded = props.studentDetails.addedStudents.map(stu => {
                stu.isChecked = false;
                return stu;
            });

            setNewlyAddedStudents(newlyAdded);
        }

    }, []);

    const isEmailEnableResult = useMemo(() => {
        var selectedStudents = newlyAddedStudents.filter(x => x.isChecked);
        if (selectedStudents.length > 0 && selectedStudents.every(x => 
            (x.parentEmail !== null && x.parentPhoneNumber === null) ||
            (x.parentEmail !== null && x.parentPhoneNumber !== null)
            )) {
            return true;
        }
        else {
            return false;
        }

    }, [newlyAddedStudents]);

    const isSMSEnableResult = useMemo(() => {
        var selectedStudents = newlyAddedStudents.filter(x => x.isChecked);
        if (selectedStudents.length > 0 && selectedStudents.every(x => 
            (x.parentPhoneNumber !== null && x.parentEmail === null) ||
            (x.parentEmail !== null && x.parentPhoneNumber !== null)
            )) {
            return true;
        }
        else {
            return false;
        }

    }, [newlyAddedStudents]);

    const isPrintEnableResult = useMemo(() => {
        var selectedStudents = newlyAddedStudents.filter(x => x.isChecked);
        if (selectedStudents.length > 0) {
            return true;
        }
        else {
            return false;
        }

    }, [newlyAddedStudents]);

    const onCheckboxToggle = (e, clickedDataId) => {
        var index = newlyAddedStudents.findIndex(x => x.studentId == clickedDataId);
        if (index > -1) {
            newlyAddedStudents[index].isChecked = e.target.checked;
            let newData = newlyAddedStudents.map(x => x);
            let isAllChecked = newData.every(x => x.isChecked);
            setAllToggleStatus(isAllChecked);
            setAllToggleIndeterminateStatus(newData.some(x => x.isChecked) && !isAllChecked)
            setNewlyAddedStudents(newData);
            updateInviteBy(newData);
        }
    }

    const onAllToggle = (e) => {
        let newData = newlyAddedStudents.map(x => {
            x.isChecked = e.target.checked;
            return x;
        });
        setNewlyAddedStudents(newData);
        setAllToggleStatus(e.target.checked);
        setAllToggleIndeterminateStatus(false);
        updateInviteBy(newData);
    }

    // Update inviteBy when student selection changes
    const updateInviteBy = (students: any[]) => {
        const selectedStudents = students.filter(f => f.isChecked);
        if (Array.isArray(selectedStudents)) {
            const shouldInviteByParent  = (inviteParentBy === InviteBy.Email && !selectedStudents.every(s => s.parentEmail && s.parentEmail !== ""))
                                        || inviteParentBy === InviteBy.Phone && !selectedStudents.every(s => s.parentPhoneNumber && s.parentPhoneNumber !== "");
            if (shouldInviteByParent) {
                setInviteParentBy(InviteBy.Print);
            }
        }
    }

    const onPreview = (showPreviewModal) => {
        form.validateFields((err, values) => {
            if (!err) {
                if (!showPreviewModal) {
                    props.resetTemplate4Preview();
                }
                setSelectedTemplateId(values.templateId);
                setShowPreviewModal(showPreviewModal);
            }
        });
    }

    const downloadInvitations = () => {
        form.validateFields((err, values) => {
            if (!err) {
                let selectedStudents = newlyAddedStudents.reduce((a, o) => o.isChecked ? a.concat(o) : a, [])
                props.downloadManualInvitations(getDownloadInvitationRequestModel(values.templateId, selectedStudents));
            }
        });
    }

    const getDownloadInvitationRequestModel = (templateId, selectedStudents: StudentRegistrationModel[]) => {

        let invitationsRequestModel: downloadInvitationsRequestModel = {
            invitationTemplateId: templateId,
            invitations: [],
            isReGenerateOrEmail: false
        };

        selectedStudents.forEach(stu => {
            let manualInvitation: invitationCodeModel = {
                codeId: stu.invitationCodeId
            };

            invitationsRequestModel.invitations.push(manualInvitation);
        })

        return invitationsRequestModel;
    }

    const emailorSMSInvitation = (isShow: boolean, isSMS: boolean = false) => {
        form.validateFields((err, values) => {
            if (!err) {
                if (isShow) {
                    let selectedStudents = newlyAddedStudents.filter(x => x.isChecked);
                    if (selectedStudents.length > 0 
                            && (isSMS ? selectedStudents.every(x => x.parentPhoneNumber !== null) : selectedStudents.every(x => x.parentEmail !== null))
                        ) {
                        setSelectedTemplateId(values.templateId);
                        setEmailInvitations(getEmailInvitationRequestModel(selectedStudents));
                    }
                    else {
                        return;
                    }
                }
                setShowEmailModal(isShow);
            }
        });
    }

    const closeRegistration = (e) => {
        e.preventDefault();
        const { schoolId, regionId, campusId, classId } = props.match.params as any;
        const path = GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId });
        history.push({ pathname : path});
    }

    const getEmailInvitationRequestModel = (selectedStudents: StudentRegistrationModel[]) => {

        let invitations: invitationCodeModel[] = [];

        selectedStudents.forEach(stu => {
            let manualInvitation: invitationCodeModel = {
                codeId: stu.invitationCodeId
            };

            invitations.push(manualInvitation);
        })

        return invitations;
    }

    const onCancelOperations = () => {
        onCancel();
    }

    const renderGender = (gender: boolean) => {
        switch (gender) {
            case true: return fmtMsg({ id: SchoolLocale.StudentRegistrationStudentMale });
            case false: return fmtMsg({ id: SchoolLocale.StudentRegistrationStudentFemale });
            default: return "";
        }
    }

    const getExistingStudentsColumns = () => {

        return [
            {
                title: fmtMsg({ id: SchoolLocale.StudentName }),
                dataIndex: StudentDetailsModelProps.name,
                width: "20%",
                render: (text, data) => {
                    return <div className="student-text" title={text}>{text}</div>;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.StudentEnName }),
                dataIndex: StudentDetailsModelProps.englishName,
                width: "15%",
                render: (text, data) => {
                    return <div className="student-text" title={text}>{text}</div>;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.StudetnBirthday }),
                dataIndex: StudentDetailsModelProps.birthday,
                width: "15%",
                render: (text, data, index) => {
                    let formattedDate = DateHelper.formatDate2Local(data.birthday)
                    return <span>{formattedDate}</span>;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.StudentGender }),
                dataIndex: StudentDetailsModelProps.gender,
                width: "15%",
                render: (text, data) => {
                    return <div className="student-text">{renderGender(data.gender)}</div>;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.StudentParentEmail }),
                dataIndex: StudentDetailsModelProps.parentEmail,
                width: "20%",
                render: (text, data) => {
                    return <div className="student-text" title={text}>{text}</div>;
                }
            },
            {
                title:  fmtMsg({ id: SchoolLocale.StudentRegistrationGenGridParentPhone }),
                dataIndex: StudentDetailsModelProps.parentPhoneNumber,
                width: "15%",
                render: (text, data) => {
                    return <div className="student-text" title={text}>{text}</div>;
                }
            }
        ];
    }

    const getNewlyAddedGridColumns = () => {

        const cols =  [
            {
                title: () => {
                    return <Checkbox disabled={newlyAddedStudents.length === 0} indeterminate={allToggleIndeterminateStatus} checked={allToggleStatus} onChange={onAllToggle}></Checkbox>
                },
                dataIndex: StudentDetailsModelProps.isChecked,
                render: (text: any, record) => {
                    return <Checkbox key={record.id} checked={record.isChecked} onChange={(e) => onCheckboxToggle(e, record.studentId)}></Checkbox>
                },
                width: "5%"
            },
            {
                title: fmtMsg({ id: SchoolLocale.StudentName }),
                dataIndex: StudentDetailsModelProps.name,
                width: isPhoneEnabled ? "15%" : "20%",
                render: (text, data) => {
                    return <div className="student-text" title={text}>{text}</div>;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.StudentEnName }),
                dataIndex: StudentDetailsModelProps.englishName,
                width: isPhoneEnabled ? "15%" : "20%",
                render: (text, data) => {
                    return <div className="student-text" title={text}>{text}</div>;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.StudetnBirthday }),
                dataIndex: StudentDetailsModelProps.birthday,
                width: "15%",
                render: (text, data, index) => {
                    let formattedDate = DateHelper.formatDate2Local(data.birthday)
                    return <span>{formattedDate}</span>;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.StudentGender }),
                dataIndex: StudentDetailsModelProps.gender,
                width: "15%",
                render: (text, data) => {
                    return <div className="student-text">{renderGender(data.gender)}</div>;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.StudentParentEmail }),
                dataIndex: StudentDetailsModelProps.parentEmail,
                width: isPhoneEnabled ? "20%" : "25%",
                render: (text, data) => {
                    return <div className="student-text" title={text}>{text}</div>;
                }
            }
        ];

        if (isPhoneEnabled) {
            cols.push({
                title: fmtMsg({ id: SchoolLocale.StudentRegistrationGenGridParentPhone }),
                dataIndex: StudentDetailsModelProps.parentPhoneNumber,
                width: "15%",
                render: (text, data) => {
                    return <div className="student-text" title={text}>{text}</div>;
                }
            });
        }

        return cols;
    }

    const getTemplates = () => {
        if (isSMSEnableResult && inviteParentBy === InviteBy.Phone) {
            return templates.filter(f => f.invitationChannel === InvitationChannel.Phone);
        } else {
            return templates.filter(f => f.invitationChannel === InvitationChannel.Email);;
        }
    }

    const onInviteByChange = (e: RadioChangeEvent) => {
        if (inviteParentBy === InviteBy.Phone || e.target.value === InviteBy.Phone) {
            form.setFieldsValue({"templateId": undefined});
        }
        setInviteParentBy(e.target.value)
    }


    return <>
            <div className="man-invite">
                <Row>
                    <div className="invite-grid-heading">{fmtMsg({ id: SchoolLocale.StudentRegistrationInviteExistingStudentsCountText })} ({existingStudents.length})</div>
                </Row>
                <Row>
                    <ExistingRegisteredStudentsTable
                        columns={getExistingStudentsColumns()}
                        dataSource={existingStudents}
                        pagination={false}
                        scroll={{ y: '140px', x: '1500px' }}
                    />
                </Row>
                <Row>
                    <div className="invite-grid-heading">{fmtMsg({ id: SchoolLocale.StudentRegistrationInviteNewStudentsCountText })} ({newlyAddedStudents.length})</div>
                </Row>
                <Row>
                    <NewlyRegisteredStudentsTable
                        columns={getNewlyAddedGridColumns()}
                        dataSource={newlyAddedStudents}
                        pagination={false}
                        scroll={{ y: '140px', x: '1500px' }}
                    />
                </Row>
                {newlyAddedStudents.length > 0 && <> 
                 <Radio.Group value={inviteParentBy} onChange={onInviteByChange}>
                    <Radio value={InviteBy.Print} disabled={!isPrintEnableResult}>
                        { fmtMsg({id: SchoolLocale.StudentRegistratinoGenPrint }) }
                    </Radio>
                    <Radio value={InviteBy.Email} disabled={!isEmailEnableResult}>
                    { fmtMsg({id: SchoolLocale.StudentRegistratinoGenEmail }) }
                    </Radio>
                    {isPhoneEnabled &&
                        <Radio value={InviteBy.Phone} disabled={!isSMSEnableResult}>
                            { fmtMsg({id: SchoolLocale.StudentRegistratinoGenSMS }) }
                        </Radio>
                    }
                 </Radio.Group>
                 <Row>
                    <GLForm layout="horizontal" className="man-invite-form" form={form}>
                        {renderFormItem(form, SchoolLocale.InvitationTemplate, "templateId", <TemplatesSelect templates={getTemplates()} />, null, true)}
                    </GLForm>
                </Row>
                    <Row>
                        <div className="actions">
                            <div>
                                <Button disabled={!isPrintEnableResult} className="man-reg__action" type="primary" onClick={(e) => downloadInvitations()}>
                                    {fmtMsg({ id: SchoolLocale.StudentRegistrationInvitePrintButtonText })}
                                </Button>
                                <Button className="man-reg__action" type="primary" onClick={(e) => onPreview(true)}>
                                    {fmtMsg({ id: SchoolLocale.StudentRegistrationInvitePreviewTemplateButtonText })}
                                </Button>
                                <Button disabled={(!isEmailEnableResult && !isSMSEnableResult) || inviteParentBy === InviteBy.Print} 
                                        className="man-reg__action" 
                                        type="primary" 
                                        onClick={(e) => emailorSMSInvitation(true, isSMSEnableResult)}>
                                    {inviteParentBy === InviteBy.Phone 
                                        ? fmtMsg({ id: SchoolLocale.StudentRegistratinoGenPreviewSMS })
                                        : fmtMsg({ id: SchoolLocale.StudentRegistrationInvitePreviewEmailButtonText })
                                    }
                                </Button>
                            </div>
                            <div>
                                <Button className="man-reg__action" type="primary" onClick={(e) => closeRegistration(e)}>
                                    {fmtMsg({ id: SchoolLocale.StudentRegistrationCloseButtonText })}
                                </Button>
                            </div>
                        </div>
                    </Row></>}
                <Row>
                    <RegistrationNavigation onCancel={onCancelOperations} cancelButtonText={fmtMsg({ id: SchoolLocale.StudentRegistrationNavigationCloseText })} isLastStep={true} closeRegistration={closeRegistration} isNewlyAddedStudents={newlyAddedStudents.length ? true : false}/>
                </Row>
                {showPreviewModal && <PreviewTemplate
                    visible={showPreviewModal}
                    templateId4Preview={selectedTemplateId}
                    onCancel={(e) => onPreview(false)}
                    isEmailTemplate={false}
                />}
                {showEmailModal && <ParentInvitation visible={showEmailModal} classId={props.schoolClass.id} templateId={selectedTemplateId} invitations={emailInvitations} onCancel={(e) => emailorSMSInvitation(false)} invitingBy={inviteParentBy}/>}

            </div>
    </>
}))));