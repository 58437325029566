import React, {useEffect, useRef, useState} from "react";
import {Checkbox, Col, notification, Radio, Row, Spin} from "antd-min";
import moment from "moment";
import {GLGlobal, LanguageDateFormat, MessageHelper, NotificationType, ServerExceptionCode} from "gl-commonui";
import {fmtMsg, InvitationChannel, isInternalUser, isInternalUserWithoutAM, mergeClasses, TYPES} from "@app/util";
import {RegistrationNavigation} from "./navigation";
import {isUndefined} from "util";
import {SchoolLocale} from "@app/locales/localeid";
import {ClassesModel} from "@app/service/class";
import {
    IStudentRegistrationService,
    StudentMatchResponseModel,
    StudentMatchResponseWithLicenseInfoModel,
    StudentRegistrationModel
} from "@app/service/school/student-registration";
import {useService} from "@app/hooks";
import RadioGroup from "antd/lib/radio/group";
import {IUserService} from "@app/service/users";
import {cloneDeep, uniq} from 'lodash';
import {InvitationStatus} from "@app/service/admin/acceptinvitation";

interface ManualRegisterationSuggestionProps {
    studentImportedData: StudentRegistrationModel[];
    enrolledStudentCount: number;
    onCancel: () => void;
    regionId: string;
    updateStudentFinalInfo: (info: any[]) => void;
    schoolClass: ClassesModel;
    onPrev: () => void;
    licenseUpdateInfo: any;
    accessibleClassIds: string[];
    invitationChannel: InvitationChannel;
}

enum MatchType {
    All = "All",
    Exact = "Exact",
    Potential = "Potential",
    New = "New"
}
interface StudentSuggestionModel extends StudentRegistrationModel {
    parentId?: string;
    classId?: string;
}

interface StudentSuggestionMatchModel {
    studentId?: string;
    parentId?: string;
    suggestions: StudentSuggestionModel[];
}

interface StudentMatchResultModel {
    exactMatches: number;
    potentialMatches: number;
    matches: StudentSuggestionMatchModel[];
}

export const ManualRegisterationSuggestions = (props: ManualRegisterationSuggestionProps, ref: React.Ref<unknown>) => {
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState<StudentSuggestionMatchModel[]>([]);
    const regionSuggestions = useRef<StudentSuggestionMatchModel[]>([]);
    const [overwriteExistingInfoAll, setOverwriteExistingInfoAll] = useState(false);
    const [overwriteAllIndeterminateStatus, setOverwriteAllIndeterminateStatus] = useState(false);
    const [existedStudentDetails, setExistedStudentDetails] = useState<StudentRegistrationModel[]>([]);
    const [studentDetails, setStudentDetails] = useState<StudentRegistrationModel[]>([]);
    const [newStudents, setNewStudents] = useState<StudentRegistrationModel[]>([]);
    const [currentStudentSelected, setCurrentStudentSelected] = useState("");
    const [currentSuggestionSelected, setSuggestionSelected] = useState(null);
    const [matchType, setMatchType] = useState(null);
    const [newMatches, setNewMatches] = useState(0);
    const studentRegistrationService = useService<IStudentRegistrationService>(TYPES.IStudentRegistrationService);
    const userService = useService<IUserService>(TYPES.IUserService);
    const exactMatches = useRef(0);
    const potentialMatches = useRef(0);

    useEffect(() => {
        // get the suggesstions
        if (props.studentImportedData) {
            getStudentSuggestions(props.studentImportedData);
        }
    }, [props.studentImportedData]);


    const getStudentSuggestions = (studentData) => {
        setLoading(true);
        studentRegistrationService.getSuggestions(props.regionId, studentData)
            .then((data: StudentMatchResultModel) => {
                // if external user then only need to filter or Account manager
                if(!isInternalUserWithoutAM()){
                    // set region level suggestions
                    regionSuggestions.current = cloneDeep(data.matches);

                    // filter user role level suggestions based on his/her accessible region, school, campus, class
                    data.matches.map(student => {
                        student.suggestions = student.suggestions.filter(s=> props.accessibleClassIds.includes(s.classId));
                    });

                    // to calculate exact matches from filtered data
                    data.exactMatches = data.matches.filter(x => x.suggestions.some(m => m.isExactMatch)).length;

                    // to calculate potential matches from filtered data
                    data.potentialMatches = data.matches.filter(x => x.suggestions.some(m => m.studentId)).length - data.exactMatches;
                }
                getUserEmailsById(data);
            })
            .catch(er => {
                setLoading(false);
                notification.error({ message: fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionGetErrorMsg }), className: "tsc-notif" });
            });
    }

    const getUserEmailsById = (suggestionData: StudentMatchResultModel) => {
        let allSuggestions = suggestionData.matches.reduce((a, o) => a.concat(o.suggestions), []);
        let parentIds = allSuggestions.reduce((a, o) => o.parentId ? a.concat(o.parentId) : a, []);
        parentIds = uniq(parentIds);
        userService
            .getUsersByPost({
                ids: parentIds
            })
            .then(reponseData => {
                if (reponseData.data !== undefined && reponseData.data.length > 0) {
                    suggestionData.matches.forEach(match => {
                        match.suggestions.map(sug => {
                            if (sug.parentId) {
                                let userDetail = reponseData.data.find(user => user.id == sug.parentId);
                                if (userDetail !== undefined) {
                                    if (!userDetail.disabled) {
                                        sug.parentEmail = userDetail.email;
                                        sug.parentPhoneNumber = userDetail.phone;
                                        sug.phoneCountryCode = userDetail.phoneCountryCode;
                                    }
                                    else {
                                        sug.parentId = null;
                                        sug.parentEmail = null;
                                        sug.parentPhoneNumber = null;
                                    }
                                }
                            }
                            return sug;
                        });
                    });
                }

                setDataFromSuggestions(suggestionData);
                setLoading(false);
            })
            .catch(er => {
                setLoading(false);
                notification.error({ message: fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionGetErrorMsg }), className: "tsc-notif" });
            });
    }

    const setDataFromSuggestions = (retdata: StudentMatchResultModel) => {
        var data = retdata.matches;
        exactMatches.current = retdata.exactMatches;
        potentialMatches.current = retdata.potentialMatches;

        let childDetailsWithOverwriteCheck = props.studentImportedData.map(x => {
            x.isOverwrite = true;
            x.isDirty = false;
            let childWithMatch = data.find(y => y.studentId === x.studentId);
            if (childWithMatch !== undefined && childWithMatch.suggestions.length > 0) {
                x.isCheckboxDisable = false;
                x.selectedMatchId = childWithMatch.suggestions[0].studentId;
                x.isExistedStudent = true;
                x.hasParent = childWithMatch.parentId !== null;
                x.isExactMatch = childWithMatch.suggestions.some(x => x.isExactMatch);
            }
            else {
                x.isCheckboxDisable = true;
                x.isOverwrite = false;
                x.selectedMatchId = null;
                x.isExistedStudent = false;
                x.hasParent = (childWithMatch !== undefined && childWithMatch.parentId !== null) ? true : false;
                x.isExactMatch = false;
            }
            return x;
        });

        let newStudents = childDetailsWithOverwriteCheck.filter(x => !x.isExistedStudent);
        setNewStudents(newStudents);
        setExistedStudentDetails(childDetailsWithOverwriteCheck.filter(x => x.isExistedStudent));


        let currentStudentDetails = [];

        if (childDetailsWithOverwriteCheck.filter(x => !x.isExistedStudent).length > 0) {
            setMatchType(MatchType.New);
            currentStudentDetails = newStudents;
        }
        else if (childDetailsWithOverwriteCheck.filter(x => x.isExistedStudent && x.isExactMatch).length > 0) {
            setMatchType(MatchType.Exact);
            currentStudentDetails = childDetailsWithOverwriteCheck.filter(x => x.isExistedStudent && x.isExactMatch);
        }
        else if (childDetailsWithOverwriteCheck.filter(x => x.isExistedStudent && !x.isExactMatch).length > 0) {

            setMatchType(MatchType.Potential);
            currentStudentDetails = childDetailsWithOverwriteCheck.filter(x => x.isExistedStudent && !x.isExactMatch);
        }

        setStudentDetails(currentStudentDetails);
        setSuggestions(data);

        let isAll = isAllOverwrite(currentStudentDetails);
        setOverwriteExistingInfoAll(isAll);
        setOverwriteAllIndeterminateStatus(currentStudentDetails.some(x => x.isOverwrite) && !isAll);

        setNewMatches(data.length - exactMatches.current - potentialMatches.current)

        // if (currentStudentDetails.length > 0) {
        //     setCurrentStudentSelected(currentStudentDetails[0].studentId);
        //     setSuggestionToSelect(data, currentStudentDetails[0].studentId);
        // }
    }

    const setSuggestionToSelect = (data, id) => {
        const potentialMatches = data.find(x => x.studentId === id);
        if (potentialMatches !== undefined && potentialMatches.suggestions.length > 0) {
            setSuggestionSelected(potentialMatches.suggestions[0].studentId);
        }
    }

    const isAllOverwrite = (data: StudentRegistrationModel[]) => {
        if(data.length > 0){
        return data.every(x => x.isOverwrite);
        }
        else
        {
            return false;
        }
    }

    const renderGender = (gender: boolean) => {
        switch (gender) {
            case true: return fmtMsg({ id: SchoolLocale.StudentRegistrationStudentMale });
            case false: return fmtMsg({ id: SchoolLocale.StudentRegistrationStudentFemale });
            default: return "";
        }
    }

    const isStringDiff = (stringOne: string, stringTwo: string) => {
        if (typeof stringOne === "string" && typeof stringTwo === "string" && stringOne.length && stringTwo.length && stringOne.trim().toLowerCase() === stringTwo.trim().toLowerCase()) {
            return false;
        }
        return true;
    }

    const renderStudentDetails = (childInfo: StudentSuggestionModel, overwriteCheck: any = null, isImportedChild: boolean = true) => {
        let isNameDiff, isEnglishNameDiff, isBirthdayDiff, isParentEmailDiff, isParentPhoneDiff, isGenderDiff = false;
        let parentStatus = <span></span>;
        if (isImportedChild) {

            if (childInfo.studentId === currentStudentSelected) {
                // Find the selected index of suggestion and get the suggestion
                let potentialMatch = suggestions.find(s => s.studentId === childInfo.studentId);
                let suggestion = null;
                if (potentialMatch !== undefined) {
                    suggestion = potentialMatch.suggestions.find(x => x.studentId === currentSuggestionSelected);
                }

                if (suggestion !== undefined && suggestion) {
                    isNameDiff = isStringDiff(childInfo.name, suggestion.name);
                    isEnglishNameDiff = isStringDiff(childInfo.englishName, suggestion.englishName);
                    isBirthdayDiff = isStringDiff(moment(childInfo.birthday).format(LanguageDateFormat[GLGlobal.intl.locale]), moment(suggestion.birthday).format(LanguageDateFormat[GLGlobal.intl.locale]));
                    isParentEmailDiff = isStringDiff(childInfo.parentEmail, suggestion.parentEmail);
                    isParentPhoneDiff = isStringDiff(childInfo.parentPhoneNumber, suggestion.parentPhoneNumber);
                    isGenderDiff = suggestion.gender !== childInfo.gender;
                }
            }
            // doing which has confirmed email
            if (childInfo.hasParent) {
                parentStatus = <Checkbox disabled={true} className="has-parent" checked={true}></Checkbox>;
            }
            else {
                parentStatus = <span></span>;
                //parentStatus = <Checkbox disabled={true} className="no-parent" checked={false}></Checkbox>;
            }
        }
        const isParentPending = childInfo.parentStatus === InvitationStatus.Init;



        return <div key={isImportedChild ? childInfo.studentId + "imported" : childInfo.studentId + "unImported"}>
            <Row>
                <Col xs={getColSize(isImportedChild)} sm={getColSize(isImportedChild)} md={getColSize(isImportedChild)} lg={getColSize(isImportedChild)} xl={getColSize(isImportedChild)}>
                    <div className="s-reg__sd-item">
                        <span className={mergeClasses(isNameDiff && "s-reg__sd-item--diff")}>{childInfo.name} </span>
                        <span className={mergeClasses(isEnglishNameDiff && "s-reg__sd-item--diff")}> ({childInfo.englishName})</span>
                        {overwriteCheck && <span className={mergeClasses("s-reg__sd-item--overwrite")}>{overwriteCheck}</span>}
                    </div>
                    <div className="s-reg__sd-item">
                    {childInfo.birthday &&
                            <span className={mergeClasses("s-reg__sd-item--in", isBirthdayDiff && "s-reg__sd-item--diff")}>
                                {childInfo.birthday && moment(childInfo.birthday).format(LanguageDateFormat[GLGlobal.intl.locale])}
                            </span>
                        }
                        {childInfo.birthday && renderGender(childInfo.gender) && <span> | </span>}
                        <span className={mergeClasses(isGenderDiff && "s-reg__sd-item--diff")}>{renderGender(childInfo.gender)}</span>
                    </div>
                    <div className="s-reg__s-parent-email">
                            {" "}
                            <div>
                                <span>
                                    {fmtMsg({
                                        id:
                                            SchoolLocale.StudentRegistrationSuggestionParentEmailTextLabel
                                    })}
                                    <span className="s-parent-email-hypen">
                                        :{" "}
                                    </span>
                                </span>{" "}
                            </div>
                            <div
                                className={mergeClasses(
                                    "s-reg__sd-item",
                                    isParentEmailDiff && "s-reg__sd-item--diff"
                                )}
                            >
                                &nbsp;{childInfo.parentEmail}&nbsp;
                                {isParentPending ? `(${fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionParentEmailPending })})` : ""}
                            </div>
                            <div> {childInfo.parentEmail && childInfo.parentEmail.length && parentStatus}</div>
                        </div>
                        <div className="s-reg__s-parent-email">
                            {" "}
                            <div>
                                <span>
                                    {fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionParentPhoneLabel })}
                                    <span className="s-parent-email-hypen">
                                        :{" "}
                                    </span>
                                </span>{" "}
                            </div>
                            <div
                                className={mergeClasses(
                                    "s-reg__sd-item",
                                    isParentPhoneDiff && "s-reg__sd-item--diff"
                                )}
                            >
                                &nbsp;{childInfo.parentPhoneNumber}&nbsp;
                            </div>
                            <div> {childInfo.parentPhoneNumber && childInfo.parentPhoneNumber && parentStatus}</div>
                        </div>
                </Col>
                {matchType === MatchType.Exact && <Col className="s-reg__extra-info" xs={9} sm={9} md={9} lg={9} xl={9}>
                    {getExactExtraInfo(childInfo.studentId)}
                </Col>}
                {isPotentialSuggestion(isImportedChild) && <Col className="s-reg__extra-info" xs={9} sm={9} md={9} lg={9} xl={9}>
                    {getPotentialSuggestionExtraInfo(childInfo)}
                </Col>}
            </Row>
        </div>
    }

    const isPotentialSuggestion = (isImportedChild: boolean) => {
        return matchType === MatchType.Potential && !isImportedChild;
    }

    const getColSize = (isImportedChild) => {
        if (matchType === MatchType.Exact || isPotentialSuggestion(isImportedChild)) {
            return 14;
        }
        else {
            return 24;
        }
    }

    const getPotentialSuggestionExtraInfo = (childInfo: StudentSuggestionModel) => {
        if (isInternalUser()) {
            return <div key={childInfo.studentId + "potentialExtraInfo"} >
                <div className="s-reg__sd-item">
                    <span className="s-reg__sd-extra-item-label">{fmtMsg({ id: SchoolLocale.StudentRegistrationSchoolLabel })}: </span>
                    <span> {childInfo.schoolName}</span>
                </div>
                <div className="s-reg__sd-item">
                    <span className="s-reg__sd-extra-item-label">{fmtMsg({ id: SchoolLocale.StudentRegistrationCampusLabel })}: </span>
                    <span> {childInfo.campusName}</span>
                </div>
                <div className="s-reg__sd-item">
                    <span className="s-reg__sd-extra-item-label">{fmtMsg({ id: SchoolLocale.StudentRegistrationClassLabel })}: </span>
                    <span> {childInfo.className}</span>
                </div>
                <div className="s-reg__sd-item">
                    <span className="s-reg__sd-extra-item-label">{fmtMsg({ id: SchoolLocale.StudentRegistrationCurrentUnitLabel })}: </span>
                    <span> {childInfo.currentUnit}</span>
                </div>
            </div>
        }
        else {
            return <div></div>
        }
    }

    const getExactExtraInfo = (studentId) => {
        const potentialMatches = suggestions.find(x => x.studentId === studentId);
        if (potentialMatches !== undefined && potentialMatches.suggestions.length > 0) {
            const firstExactMatch = potentialMatches.suggestions[0];
            return <div key={studentId + "exactMatchInfo"} >
                <div className="s-reg__sd-item">
                    <span className="s-reg__sd-extra-item-label">{fmtMsg({ id: SchoolLocale.StudentRegistrationSchoolLabel })}: </span>
                    <span> {firstExactMatch.schoolName}</span>
                </div>
                <div className="s-reg__sd-item">
                    <span className="s-reg__sd-extra-item-label">{fmtMsg({ id: SchoolLocale.StudentRegistrationCampusLabel })}: </span>
                    <span> {firstExactMatch.campusName}</span>
                </div>
                <div className="s-reg__sd-item">
                    <span className="s-reg__sd-extra-item-label">{fmtMsg({ id: SchoolLocale.StudentRegistrationClassLabel })}: </span>
                    <span> {firstExactMatch.className}</span>
                </div>
            </div>
        }
        else {
            return <div></div>
        }
    }

    const suggestionSelected = (id, studentId) => {
        if (isNewOrExactMatchTypeSelected()) {
            return;
        }

        let updatedChildDetails = studentDetails.map(x => {
            if (x.studentId === studentId) {
                x.selectedMatchId = id;
                if (id === null) {
                    x.isOverwrite = false;
                    x.isCheckboxDisable = true;
                }
                else {
                    x.isOverwrite = overwriteExistingInfoAll;
                    x.isCheckboxDisable = false;
                }
            }
            return x;
        });

        let existedStudentList = existedStudentDetails.map(x => {
            let studentInfo = updatedChildDetails.find(y => y.studentId == x.studentId);
            if (studentInfo !== undefined) {
                x.selectedMatchId = studentInfo.selectedMatchId;
                x.isOverwrite = studentInfo.isOverwrite;
                x.isCheckboxDisable = studentInfo.isCheckboxDisable;
            }
            return x;
        });

        let isAll = isAllOverwrite(updatedChildDetails);
        setExistedStudentDetails(existedStudentList);
        setOverwriteExistingInfoAll(isAll);
        setOverwriteAllIndeterminateStatus(updatedChildDetails.some(x => x.isOverwrite) && !isAll);
        setSuggestionSelected(id);
        setStudentDetails(updatedChildDetails);
    }

    const studentSelected = (id, selectedMatchId) => {
        setSuggestionSelected(selectedMatchId);
        setCurrentStudentSelected(id);

        let updatedChildDetails = studentDetails.map(x => {
            if (x.studentId === id && !x.isDirty) {
                x.isDirty = true;
            }
            return x;
        });


        if (matchType === MatchType.New) {
            let updatedNewChildDetails = newStudents.map(x => {
                if (x.studentId === id && !x.isDirty) {
                    x.isDirty = true;
                }
                return x;
            });
            setNewStudents(updatedNewChildDetails);
        }
        else {
            let updatedExistingChildDetails = existedStudentDetails.map(x => {
                if (x.studentId === id && !x.isDirty) {
                    x.isDirty = true;
                }
                return x;
            });

            setExistedStudentDetails(updatedExistingChildDetails);
        }

        setStudentDetails(updatedChildDetails);


    }

    const renderPotentialMatches = () => {
        const potentialMatches = suggestions.find(x => x.studentId === currentStudentSelected);
        var suggetionElement = [];
        if (potentialMatches !== undefined) {
            suggetionElement = potentialMatches.suggestions.map((s, index) => {
                return <div
                    key={index + '' + s.studentId + 'potentialMatches'}
                    onClick={() => s.studentId !== currentSuggestionSelected && suggestionSelected(s.studentId, potentialMatches.studentId)}
                    className={mergeClasses(
                        "s-reg__p-match",
                        currentSuggestionSelected === s.studentId && "s-reg__p-match--selected",
                        currentSuggestionSelected !== s.studentId && "s-reg__p-match--selectable")}
                >
                    {renderStudentDetails(s, null, false)}
                </div>
            });
        }
        {
            potentialMatches !== undefined && potentialMatches.suggestions.length > 0 &&
                suggetionElement.push(<div
                    key={currentStudentSelected + 'createNew'}
                    onClick={() => currentSuggestionSelected != null && suggestionSelected(null, potentialMatches.studentId)}
                    className={mergeClasses(
                        "s-reg__p-match",
                        currentSuggestionSelected === null && "s-reg__p-match--selected",
                        currentSuggestionSelected != null && "s-reg__p-match--selectable")}
                >
                    {fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionCreateNewLabel })}
                </div>)
        }
        {
            (isNewOrExactMatchTypeSelected() || potentialMatches === undefined) &&
                suggetionElement.push(<div key={currentStudentSelected + 'noPotential'} className="no-record">{fmtMsg({ id: SchoolLocale.StudentRegistrationNoData })}</div>)
        }
        return suggetionElement;
    }

    const isNewOrExactMatchTypeSelected = () => {
        if (matchType === MatchType.New || matchType === MatchType.Exact) {
            return true;
        }
        return false;
    }

    const getNoDataMessage = () => {

        let localeId = SchoolLocale.StudentRegistrationNoData;

        if (matchType === MatchType.New) {
            localeId = SchoolLocale.StudentRegistrationNoNewData;
        }
        else if (matchType === MatchType.Exact) {
            localeId = SchoolLocale.StudentRegistrationNoExactData;
        }
        else if (matchType === MatchType.Potential) {
            localeId = SchoolLocale.StudentRegistrationNoPotentialData;
        }

        return fmtMsg({ id: localeId });
    }

    const onAllOverriteChange = (event) => {

        if (isNewOrExactMatchTypeSelected()) {
            return;
        }

        let childDetailsWithOverwriteCheck = studentDetails.map(x => {
            if (x.selectedMatchId) {
                x.isOverwrite = event.target.checked;
            }
            else {
                x.isOverwrite = false;
            }

            return x;
        });

        let existedStudentList = existedStudentDetails.map(x => {
            let studentInfo = childDetailsWithOverwriteCheck.find(y => y.studentId == x.studentId);
            if (studentInfo !== undefined) {
                x.isOverwrite = studentInfo.isOverwrite;
            }
            return x;
        });

        setExistedStudentDetails(existedStudentList);
        setStudentDetails(childDetailsWithOverwriteCheck);
        setOverwriteExistingInfoAll(event.target.checked);
        setOverwriteAllIndeterminateStatus(false);
    }

    const updateOverwrite = (e, id) => {

        if (isNewOrExactMatchTypeSelected()) {
            return;
        }

        let updatedChildDetails = studentDetails.map(x => {
            if (x.studentId === id) {
                x.isOverwrite = e.target.checked;
            }
            return x;
        });

        let existedStudentList = existedStudentDetails.map(x => {
            let studentInfo = updatedChildDetails.find(y => y.studentId == x.studentId);
            if (studentInfo !== undefined) {
                x.isOverwrite = studentInfo.isOverwrite;
            }
            return x;
        });

        setExistedStudentDetails(existedStudentList);
        setStudentDetails(updatedChildDetails);
        let isAll = isAllOverwrite(updatedChildDetails);
        setOverwriteExistingInfoAll(isAll);
        setOverwriteAllIndeterminateStatus(updatedChildDetails.some(x => x.isOverwrite) && !isAll);
    }

    const getNextMatchType = (currentMatchType) => {
        // This will get the next match type
        if ((currentMatchType === MatchType.New && exactMatches.current == 0 && potentialMatches.current != 0) ||
            (currentMatchType === MatchType.Exact && potentialMatches.current != 0)) {
            return MatchType.Potential;
        }
        else if (currentMatchType === MatchType.New && exactMatches.current != 0) {
            return MatchType.Exact;
        }
        return;
    }

    const onNextButtonClick = () => {
        const currentMatchType = getNextMatchType(matchType);
        if(!currentMatchType) {
            saveUpdateStudentInfo();
        }
        onMatchTypeChange(currentMatchType);
    }

    const saveUpdateStudentInfo = () => {
        var availableLicenses = 0;
        if (props.schoolClass.studentCount) {
            availableLicenses = props.schoolClass.studentCount - props.enrolledStudentCount;
        }

        var dataToSave = getSaveModel();

        if (dataToSave.errors.length > 0) {
            dataToSave.errors.forEach(msg => {
                MessageHelper.Message(NotificationType.Failed, msg);
            })
        }
        else {

            saveUpdateStudentDetails(dataToSave.saveData);

            // let newAddedCount = dataToSave.saveData.filter(stu => isNull(stu.studentId)).length;
            // if (availableLicenses >= newAddedCount) {
            //     saveUpdateStudentDetails(dataToSave.saveData);
            // }
            // else {
            //     const msg = fmtMsg({ id: SchoolLocale.StudetnInviteFull });
            //     MessageHelper.Message(NotificationType.Failed, msg);
            // }
        }
    }


    const getStudentNameById = (id) => {
        var matchedDetail = studentDetails.find(x => x.studentId === id);
        if (matchedDetail !== undefined) {
            return matchedDetail.name;
        }
        return "";
    }

    const getSaveModel = () => {
        const { invitationChannel } = props;
        var responseModel: StudentMatchResponseModel[] = [];
        var errors = [];
        var mergedStudentDetails = existedStudentDetails.concat(newStudents);
        mergedStudentDetails.forEach(stu => {
            let regionChildWithMatch = regionSuggestions.current.find(y => y.studentId === stu.studentId);
            let childWithMatch = suggestions.find(y => y.studentId === stu.studentId);
            // Child have suggestions
            if (childWithMatch !== undefined) {
                let studentModel: StudentMatchResponseModel = {
                    studentId: null,
                    name: null,
                    englishName: null,
                    birthday: null,
                    gender: false,
                    parentEmail: null,
                    parentPhoneNumber: null,
                    phoneCountryCode: null,
                    isOverwrite: false,
                    parentId: null,
                    classId: null,
                    invitationChannel,
                    oldParentEmail: null
                };

                // only if external user is registering then need to set
                if(!isInternalUser()) {
                    // if region level suggestion count is not same as user suggestion count then
                    // it will go in verification table
                    studentModel.isSuggestionMismatched = regionChildWithMatch.suggestions.length != childWithMatch.suggestions.length;
                }

                studentModel.isOverwrite = stu.isOverwrite;
                if (stu.selectedMatchId === null) {
                    studentModel.parentId = childWithMatch.parentId;
                    studentModel.studentId = null;
                    studentModel.name = stu.name;
                    studentModel.englishName = stu.englishName;
                    studentModel.parentEmail = stu.parentEmail;
                    studentModel.parentPhoneNumber = stu.parentPhoneNumber;
                    studentModel.phoneCountryCode = stu.parentPhoneNumber && stu.parentPhoneNumber.length ? stu.phoneCountryCode : null;
                    studentModel.gender = stu.gender;
                    studentModel.birthday = stu.birthday;
                    studentModel.oldParentEmail = null;
                }
                else {
                    let suggestion = childWithMatch.suggestions.find(x => x.studentId === stu.selectedMatchId);

                    if (suggestion === undefined) {
                        errors.push(fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionErrorMsg }, { name: getStudentNameById(stu.studentId) }));
                    }
                    else {
                        studentModel.studentId = suggestion.studentId;
                        studentModel.classId = suggestion.classId;
                        studentModel.oldParentEmail = suggestion.parentEmail;
                        if (stu.isOverwrite) {
                            studentModel.name = stu.name;
                            studentModel.englishName = stu.englishName;
                            studentModel.gender = stu.gender;
                            studentModel.birthday = stu.birthday;

                            if (studentModel.invitationChannel === InvitationChannel.Email
                                    ? (!stu.parentEmail || stu.parentEmail === "")
                                        || (suggestion.parentId === childWithMatch.parentId && suggestion.parentEmail === stu.parentEmail)
                                    :  (!stu.parentPhoneNumber || stu.parentPhoneNumber === "")
                                        || (suggestion.parentId === childWithMatch.parentId && suggestion.parentPhoneNumber === stu.parentPhoneNumber)) {
                                studentModel.parentEmail = suggestion.parentEmail;
                                studentModel.parentPhoneNumber = suggestion.parentPhoneNumber;
                                studentModel.phoneCountryCode = suggestion.parentPhoneNumber && suggestion.parentPhoneNumber.length
                                                                    ? suggestion.phoneCountryCode
                                                                    : null;
                                studentModel.parentId = suggestion.parentId;
                            }
                            else {
                                studentModel.parentEmail = stu.parentEmail;
                                studentModel.parentPhoneNumber = stu.parentPhoneNumber;
                                studentModel.phoneCountryCode = stu.parentPhoneNumber && stu.parentPhoneNumber.length ? stu.phoneCountryCode : null;
                                studentModel.parentId = childWithMatch.parentId;
                            }
                        }
                        else {
                            // Case: When not overwriting the existing information
                            studentModel.name = suggestion.name;
                            studentModel.englishName = suggestion.englishName;
                            studentModel.parentEmail = suggestion.parentEmail;
                            studentModel.parentPhoneNumber = suggestion.parentPhoneNumber;
                            studentModel.gender = suggestion.gender;
                            studentModel.birthday = suggestion.birthday;
                            studentModel.phoneCountryCode =  suggestion.parentPhoneNumber && suggestion.parentPhoneNumber.length
                                                                        ? suggestion.phoneCountryCode
                                                                        : null;
                            if (studentModel.invitationChannel === InvitationChannel.Email
                                    ? !suggestion.parentEmail && (!!stu.parentEmail || stu.parentEmail !== "")
                                    : !suggestion.parentPhoneNumber && (!!stu.parentPhoneNumber || stu.parentPhoneNumber !== "")) {
                                studentModel.parentId = childWithMatch.parentId;
                                studentModel.parentEmail = stu.parentEmail;
                                studentModel.parentPhoneNumber = stu.parentPhoneNumber;
                                studentModel.phoneCountryCode = stu.parentPhoneNumber && stu.parentPhoneNumber.length ? stu.phoneCountryCode : null;
                            }
                            else {
                                studentModel.parentId = suggestion.parentId;
                                studentModel.parentPhoneNumber = suggestion.parentPhoneNumber;
                                studentModel.phoneCountryCode = suggestion.parentPhoneNumber && suggestion.parentPhoneNumber.length
                                                                    ? suggestion.phoneCountryCode
                                                                    : null;
                                studentModel.parentEmail = suggestion.parentEmail;
                            }
                        }
                    }

                }

                responseModel.push(studentModel);
            }
            else {
                errors.push(fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionMatchErrorMsg }, { name: getStudentNameById(stu.studentId) }));
            }

        });

        return { "saveData": responseModel, "errors": errors };
    }

    const saveUpdateStudentDetails = (detailsToSave: StudentMatchResponseModel[]) => {
        setLoading(true);

        const studentDataWithLicenseInfo: StudentMatchResponseWithLicenseInfoModel = {
            students: detailsToSave,
            licenseInfo: props.licenseUpdateInfo
        };
        studentRegistrationService.saveUpdateStudents(props.schoolClass.id,props.regionId, studentDataWithLicenseInfo)
            .then(data => {
                setLoading(false);
                if (data.code !== undefined) {
                    notification.error({ message: fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionSaveErrorMsg }), className: "tsc-notif" });
                }
                else {
                    props.updateStudentFinalInfo(data);
                }
            })
            .catch(er => {
                if (er.error_code && er.error_description && er.error_code === ServerExceptionCode.ExceedStudentLicenseLimitationException) {
                    notification.error({ message: fmtMsg({ id: SchoolLocale.StudentRegistrationFullInviteMsg }, { licenseCount: er.error_description }), className: "tsc-notif" });
                }
                else if (er.error_code && er.error_description && er.error_code === ServerExceptionCode.RegionSettingLimitException) {
                    notification.error({ message: fmtMsg({ id: SchoolLocale.ClassLicenseLimitInvalid }), className: "tsc-notif" });
                }
                setLoading(false);
            });
    }

    const renderAllChilds = () => {

        if (studentDetails.length == 0) {
            return <div key="all-no-record" className="no-record">{getNoDataMessage()}</div>
        }

        return studentDetails.map((c, index) => {
            if (!isNewOrExactMatchTypeSelected()) {
                let overwriteCheck = <Checkbox disabled={c.isCheckboxDisable} onChange={(e) => updateOverwrite(e, c.studentId)} className="s-reg__o-check" checked={c.isOverwrite}></Checkbox>;
                return <div key={index + "importedStudents"} className={mergeClasses("s-reg__s-wrap",
                    !c.isDirty && "s-reg__p-match--untouched",
                    c.isDirty && "s-reg__p-match--touched",
                    c.studentId === currentStudentSelected && "s-reg__p-match--selected",
                    c.studentId !== currentStudentSelected && "s-reg__p-match--selectable")}
                    onClick={() => c.studentId !== currentStudentSelected && studentSelected(c.studentId, c.selectedMatchId)}>
                    <div className="s-reg__s-detail">
                        {renderStudentDetails(c, overwriteCheck)}

                    </div>

                </div>
            }
            else {
                return <div key={index + "importedStudents"} className={mergeClasses("s-reg__s-wrap",
                    c.studentId !== currentStudentSelected && "s-reg__p-match--selectable")}>
                    <div className="s-reg__s-detail">
                        {renderStudentDetails(c)}
                    </div>
                </div>
            }
        }
        )
    }

    const isNextDisable = () => {
        let potentialDetails = existedStudentDetails.filter(x => x.isExistedStudent && !x.isExactMatch);
        if (matchType === MatchType.Potential && potentialDetails.length > 0) {
            return !potentialDetails.every(x => x.isDirty);
        }
        else {
            return false;
        }
    }

    const onMatchTypeChange = (e) => {

        let detailsToRender = [];
        let matchSelected = isUndefined(e.target) ? e : e.target.value;

        setMatchType(matchSelected);

        if (MatchType.Exact === matchSelected) {
            detailsToRender = existedStudentDetails.filter(x => x.isExactMatch);
        }
        else if (MatchType.Potential === matchSelected) {
            detailsToRender = existedStudentDetails.filter(x => !x.isExactMatch);
        }
        else if (MatchType.New === matchSelected) {
            detailsToRender = newStudents;
        }
        setStudentDetails(detailsToRender);
        let isAll = isAllOverwrite(detailsToRender);
        setOverwriteExistingInfoAll(isAll);
        setOverwriteAllIndeterminateStatus(detailsToRender.some(x => x.isOverwrite) && !isAll);
        setCurrentStudentSelected("");
        setSuggestionSelected(null);

        // if (detailsToRender.length > 0) {
        //     setCurrentStudentSelected(detailsToRender[0].studentId);
        //     setSuggestionSelected(detailsToRender[0].selectedMatchId);
        // }
        // else {
        //     setCurrentStudentSelected("");
        //     setSuggestionSelected(null);
        // }
    }

    const getRequiredNoteSection = () => {
        if (matchType === MatchType.New) {
            return <>
                <div>{fmtMsg({ id: SchoolLocale.StudentRegistrationNewNote1 })} &nbsp;
                {fmtMsg({ id: SchoolLocale.StudentRegistrationNewNote2 })}</div>
            </>
        }
        else if (matchType === MatchType.Exact) {
            return <>
                <div>{fmtMsg({ id: SchoolLocale.StudentRegistrationExactNote1 })} &nbsp;
                {fmtMsg({ id: SchoolLocale.StudentRegistrationExactNote2 })}</div>
            </>
        }
        else if (matchType === MatchType.Potential) {
            return <>
                <div>{fmtMsg({ id: SchoolLocale.StudentRegistrationPotentialNote1 })}&nbsp;
                {fmtMsg({ id: SchoolLocale.StudentRegistrationPotentialNote2 })} &nbsp;
                {fmtMsg({ id: SchoolLocale.StudentRegistrationPotentialNote3 })}
                </div>
            </>
        }
        else {
            return <></>
        }
    }

    const getPreviousMatchType = (currentMatchType) => {

        // calculate the previous match type based on currentMatchType and counts of previous match types.
        if((currentMatchType === MatchType.Potential && exactMatches.current == 0 && newMatches != 0) ||
            (currentMatchType === MatchType.Exact && newMatches != 0)) {
                return MatchType.New;
        }
        else if(currentMatchType === MatchType.Potential && exactMatches.current != 0) {
            return MatchType.Exact;
        }
        return;
    }

    const onPrevClick = () => {
        const currentMatchType = getPreviousMatchType(matchType);
        if(!currentMatchType) {
            props.onPrev();
        }
        onMatchTypeChange(currentMatchType);
    }

    return <>
        <Spin spinning={loading}>
            <div className="man-reg__slidein">
                <Row gutter={24} className="s-reg__s-container">
                    <Col>
                        <div className="s-reg__s-head">
                            <h3 className="s-reg__n-student">{fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionImportedHeading })}&nbsp;({existedStudentDetails.length + newStudents.length})</h3>
                        </div>
                    </Col>
                    <Col>
                        <div className="s-reg__s-head"><div className="matches-states">
                            <RadioGroup value={matchType} onChange={onMatchTypeChange}>
                                <Radio.Button className="new-records" value={MatchType.New}>{fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionNewStudentCountText })} &nbsp;({newStudents.length})</Radio.Button >
                                <Radio.Button className="exact-matches" value={MatchType.Exact}>{fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionExactMatchCountText })} &nbsp;({exactMatches.current})</Radio.Button >
                                <Radio.Button className="potential-matches" value={MatchType.Potential}>{fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionPotentialMatchCountText })} &nbsp;({potentialMatches.current})</Radio.Button >
                            </RadioGroup>
                        </div></div>
                    </Col>
                    <Col>
                        <div className="s-reg__sug-note">
                            {getRequiredNoteSection()}
                        </div>
                    </Col>
                    <Col>
                        {!isNewOrExactMatchTypeSelected() && <div className="s-reg__s-head-owrite">{fmtMsg({ id: SchoolLocale.StudentRegistrationSuggestionOverwriteCheckLabel })}<Checkbox disabled={isNewOrExactMatchTypeSelected()} checked={overwriteExistingInfoAll} indeterminate={overwriteAllIndeterminateStatus} onChange={onAllOverriteChange}></Checkbox></div>}
                    </Col>

                    {isNewOrExactMatchTypeSelected() &&
                        <Col >
                            <div className="s-reg__imported-section">{renderAllChilds()}</div>
                        </Col>}

                    {!isNewOrExactMatchTypeSelected() &&
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div className="s-reg__imported-section">{renderAllChilds()}</div>
                        </Col>}

                    {!isNewOrExactMatchTypeSelected() && <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="s-reg__potential-section">{renderPotentialMatches()}</div>
                    </Col>}
                </Row>
            </div>
            <RegistrationNavigation onCancel={props.onCancel} isNextDisable={isNextDisable()} onNextClick={onNextButtonClick} isSecondStep={true} onPrevClick={onPrevClick} nextButtonText={fmtMsg({ id: matchType === MatchType.Potential ? SchoolLocale.StudentRegistrationNavigationSaveText : SchoolLocale.StudentRegistrationNavigationNextText })} />
        </Spin>
    </>

}
