import React, { useEffect, useRef, useState } from "react";
import "./student-manual-registration-page.less";
import { ManualRegisteration } from "./manual-registration";
import { SchoolClassModel, ClassesModel } from "@app/service/class";
import { connect, GLGlobal, StateType, withRouter } from "gl-commonui";
import { ClassHelper, fmtMsg, GSSchoolAction, isRegisterManualDisable, ManualRegistrationPageStep, ManualRegistrationType, registerStudentVisibilityCondition, RegistrationStepTriggerType, StudentInvitationType, TYPES } from "@app/util";
import { SchoolLocale } from "@app/locales/localeid";
import { Icon } from "antd";
import { getClassInfo, getLicense, updateLicense, updateLicenseWithMaterial, setFutureAnnualOuter, reload, updateLicenseCounts } from "@app/states/school/class";
import { SchoolModel } from "@app/service/schools";
import { LicenseModel } from "../../class";
import { useService } from "@app/hooks";
import { IRegionService, RegionModel } from "@app/service/admin/regions";
import { MainTitle } from "@app/components";
import { isEmpty } from "lodash";

interface StudentManualRegistrationProps extends LicenseModel {
    total: number;
    model: SchoolClassModel;
    disabled: boolean;
    schoolClass: ClassesModel;
    school: SchoolModel;
    registrationType: StudentInvitationType;
    getClassInfo: (d) => void;
    setFutureAnnualOuter: (d) => void;
    langLoaded?: string;
    classRoles: any;
    futureAnnualOuter: boolean;
    match: any;
    location: any;
    getLicense: (d) => void;
    updateLicense: (d: { schoolId: string, classId: string, data: any }) => void;
    updateLicenseWithMaterial: (d: { schoolId: string, classId: string, data: any }) => void;
    reload: (d) => void;
    licenseUpdated: boolean;
    students?: any;
    updateLicenseCounts: (d) => void;
}

const StudentManualRegistration = (props: StudentManualRegistrationProps) => {
    const [selectedStage, setSelectedStage] = useState(ManualRegistrationPageStep.Registration);
    const [stepTrigger, setStepTrigger] = useState(RegistrationStepTriggerType.None);
    const [currentStage, setCurrentStage] = useState(ManualRegistrationPageStep.Registration);
    const isChildModified = useRef(false);
    const regionService = useService<IRegionService>(TYPES.IRegionService);
    const [regionInfo, setRegionInfo] = useState<RegionModel>();
    const [registrationType, setRegistrationType] = useState<ManualRegistrationType>(ManualRegistrationType.Single);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        const { reload, licenseUpdated, futureAnnualOuter, classRoles, school: { allowSchoolEditLicense } } = props;
        const canEditLicense = ClassHelper.canEditLicense(classRoles, allowSchoolEditLicense) && !futureAnnualOuter;
        const isActionValid = GLGlobal.isActionValid(GSSchoolAction.SaveLicense);

        if (canEditLicense && isActionValid) {
            if (licenseUpdated) {
                reload({ licenseUpdated: false })
                loadData();
            }
        }
    }, [props.licenseUpdated]);

    const loadData = () => {
        const {
            getClassInfo,
            match: { params },
            location,
            setFutureAnnualOuter,
            getLicense,
            school
        } = props;

        const { schoolId, classId, regionId } = params;
        setFutureAnnualOuter(params);
        getClassInfo({ ...params, locationState: location.state });
        school.id && getLicense({ schoolId, classId });
        getRegionInfo();
    }

    useEffect(() => {

        const {
            match: { params },
            getLicense,
            school,
            curriculumType
        } = props;

        const { schoolId, classId } = params;

        if (school.id && typeof curriculumType === "undefined") {
            getLicense({ schoolId, classId });
        }

    }, [props.school.id]);


    const setModificationStatus = (modificationStatus) => {
        isChildModified.current = modificationStatus;
    }

    const getRegionInfo = () => {
        const { match: { params: { regionId, classId } } } = props;
        const tasks = [
            regionService.getRegionBillingInfo({ id: regionId }),
            regionService.getSchoolCycleInvoiceGenerated({ regionId, schoolClassId: classId })
        ];
        Promise.all(tasks).then(res => {
            const [regionInfo, schoolClassInvoiceInfo] = res;
            regionInfo.schoolClassInvoiceInfo = schoolClassInvoiceInfo;
            setRegionInfo(regionInfo);
        });
    }

    const getClassName = (currectProcessingStage, selectedStage, state) => {
        if (currectProcessingStage > selectedStage && selectedStage == state) {
            return `green selected`;
        }
        if (currectProcessingStage > state) {
            return `green`;
        } else if (currectProcessingStage == state) {
            return `orange`;
        } else {
            return `black`;
        }
    }

    const resetStepTrigger = () => {
        setStepTrigger(RegistrationStepTriggerType.None);
    }

    const triggerStep = (currectProcessingStage, stage) => {
        if (currectProcessingStage >= stage) {
            let trigger = RegistrationStepTriggerType.None;

            if (stage === ManualRegistrationPageStep.Suggestion) {
                trigger = registrationType === ManualRegistrationType.Single ? RegistrationStepTriggerType.Single : RegistrationStepTriggerType.Bulk;
                if (stage !== selectedStage) {
                    setStepTrigger(trigger);
                }
            }
            else {
                showTab(currectProcessingStage, stage);
            }

        }
    }

    const setManulRegistrationType = (manualRegistrationType: ManualRegistrationType) => {
        setRegistrationType(manualRegistrationType);
    }

    const showTab = (currectProcessingStage, stage) => {
        if (currectProcessingStage >= stage) {
            resetStepTrigger();
            setSelectedStage(stage);
        }
    }

    const setPageSetup = (stage) => {
        let isCurrent = false;
        if (currentStage < stage) {
            isCurrent = true;
            setCurrentStage(stage);
        }
        showTab((isCurrent ? stage : currentStage), stage);
    }

    const getStageName = (stage) => {
        switch (stage) {
            case ManualRegistrationPageStep.Registration:
                return fmtMsg({ id: SchoolLocale.StudentRegistrationImportStep });
            case ManualRegistrationPageStep.Suggestion:
                return fmtMsg({ id: SchoolLocale.StudentRegistrationMatchStep });
            case ManualRegistrationPageStep.Invitation:
                return fmtMsg({ id: SchoolLocale.StudentRegistrationGenerateStep });
        }
    }

    const progressTypes = () => {
        return {
            registrationTypes:
                new Set([
                    [ManualRegistrationPageStep.Registration, "upload"],
                    [ManualRegistrationPageStep.Suggestion, "shrink"],
                    [ManualRegistrationPageStep.Invitation, "printer"]
                ])
        }
    }

    const renderStages = () => {
        const currectProcessingStage = currentStage;
        const currentProgress = progressTypes().registrationTypes;
        return [...currentProgress].map((v, k) => {
            const color = getClassName(currectProcessingStage, selectedStage, v[0]);
            const allowClick = currectProcessingStage >= v[0] && selectedStage !== ManualRegistrationPageStep.Invitation;
            const disabledClass = allowClick ? `` : `disabled`;
            const title = getStageName(v[0]);
            return <div key={k} className={`${color} menu-item ${disabledClass}`} onClick={() => allowClick && triggerStep(currectProcessingStage, v[0])}>
                <div className="icon-container">
                    <div className="circle">
                        <Icon type={v[1]} />
                    </div>
                </div>
                <div className="title">{title}</div>
            </div>
        });

    }

    const hasLicenceHistory = () => {
        return !props.disabled && GLGlobal.isActionValid(GSSchoolAction.EditLicenseEntry);
        // return !props.disabled && GLGlobal.isActionValid(GSSchoolAction.EditLicenseEntry) && isInternalUser();
    }

    const renderRequiredSection = () => {
        const hasRegisterStudent = !props.disabled && GLGlobal.isActionValid(GSSchoolAction.InviteStudents) && registerStudentVisibilityCondition(props.model.studentRegistrationTypeId);

        if (isEmpty(props.model)) {
            return <></>
        }
        else if (!hasRegisterStudent || isRegisterManualDisable(props.model.studentRegistrationTypeId)) {
            return <div className='access-denied'>
                <h1>{fmtMsg({ id: SchoolLocale.StudentRegistrationAccessDenied })}</h1>
                <p>{fmtMsg({ id: SchoolLocale.StudentRegistrationAccessDeniedDescription })}</p>
            </div>
        }
        else {
            return <>
                <MainTitle plain={fmtMsg({ id: SchoolLocale.StudentRegistrationManualSecHeading })} />
                <div className="steps-section">
                    <div className="progress">
                        {renderStages()}
                    </div>
                    <ManualRegisteration resetStepTrigger={resetStepTrigger} passRegistrationTypeToParent={setManulRegistrationType} stepTrigger={stepTrigger} {...props} regionInfo={regionInfo} hasLicenceHistory={hasLicenceHistory()} selectedPageStep={selectedStage} passModificationStatus={setModificationStatus} passPageStep={setPageSetup} />
                </div>
            </>
        }
    }

    return <div className="gs-container gs-container--yspace gs-container--fw student-manual-registration-section">
        {renderRequiredSection()}
    </div>;
}

export const StudentManualRegistrationPage = withRouter(connect(
    ({ intl: { langLoaded }, schoolClass: { list, total, students, model, disabled, futureAnnualOuter, licenseUpdated, classRoles, license: { counts, period, billedFrom, lastBilledPeriod, curriculumType, subTypeLable, subType, startDate } }, school: { current: school } }: StateType) => ({
        langLoaded,
        classes: list,
        students,
        total,
        model,
        disabled,
        futureAnnualOuter,
        licenseUpdated,
        classRoles,
        counts,
        school,
        curriculumType,
        subTypeLable,
        subType,
        startDate,
        period,
        billedFrom,
        lastBilledPeriod,
        registrationType: StudentInvitationType.ManualRegistration
    }), {
    getClassInfo,
    getLicense,
    updateLicense,
    updateLicenseWithMaterial,
    setFutureAnnualOuter,
    updateLicenseCounts,
    reload,
}, ({ state: { classes, students } }) => ({
    schoolClass: classes[0] || {}
}))(StudentManualRegistration));