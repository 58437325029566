import React from "react";
import { Button } from "antd-min";
import { fmtMsg } from "@app/util";
import { SchoolLocale } from "@app/locales/localeid";
import { GLUtil } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";

interface RegistrationNavigationProps {
    onCancel: () => void;
    onNextClick: () => void;
    onPrevClick: () => void;
    isLastStep: boolean;
    isNextDisable: boolean;
    isSecondStep: boolean;
    nextButtonText?: string;
    cancelButtonText?: string;
    showCancelButton: boolean;
    closeRegistration: (e) => void;
    isNewlyAddedStudents: boolean;
}

export const RegistrationNavigation = (props: RegistrationNavigationProps) => {
    const { showCancelButton, onCancel, onNextClick, onPrevClick, isLastStep, isNextDisable, isSecondStep, nextButtonText, cancelButtonText, isNewlyAddedStudents } = props;

    return <div className="man-reg__steps-action">
        {isSecondStep && (<Button className="man-reg__action" type="primary" onClick={() => onPrevClick && onPrevClick()}>
            {fmtMsg({ id: SchoolLocale.StudentRegistrationNavigationPreviousText })}
        </Button>)}
        {showCancelButton && <Button className="man-reg__action" type="primary" onClick={() => onCancel && onCancel()}>
            {cancelButtonText}
        </Button>}
        {!isLastStep && (
            <Button disabled={isNextDisable} className="man-reg__action man-reg__action--primary" type="primary" onClick={() => onNextClick()}>
                {nextButtonText}
            </Button>
        )}
        {isLastStep && !isNewlyAddedStudents && <Button className="man-reg__action" type="primary" onClick={(e) => props.closeRegistration(e)}>
            {fmtMsg({ id: SchoolLocale.StudentRegistrationCloseButtonText })}
        </Button>}
    </div>
}

RegistrationNavigation.defaultProps = {
    onNextClick: () => { },
    onCancel: () => { },
    onPrevClick: () => { },
    isNextDisable: false,
    isLastStep: false,
    isSecondStep: false,
    nextButtonText: fmtMsg({ id: SchoolLocale.StudentRegistrationNavigationNextText }),
    cancelButtonText: fmtMsg({ id: SchoolLocale.StudentRegistrationNavigationCancelText }),
    showCancelButton: false
};