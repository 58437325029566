import * as React from "react";
import { Component } from "react";
import classNames from "classnames";
import { Table, Icon } from "antd-min";
import { TableLocale, TableProps, ColumnProps, SortOrder, AdditionalCellProps } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import FilterDropdown from "antd/lib/table/filterDropdown";
import { treeMap } from "antd/lib/table/util";
import { IResourceOperation, PaginationParams } from "gl-commonui";
import "./gl-table.less";

export enum ColumnSortOrder {
  Asc = "ascend",
  Desc = "descend"
}

export class GLColumn<T> extends Column<T> {
  static defaultProps = {
    sorter: false
  };
}

export interface GLTableProps<T> extends TableProps<T> {
  resOpers?: IResourceOperation<T>;
}

export class GLTable<T> extends Table<T> {
  //customize column sort ui
  renderColumnsDropdown(
    prefixCls: string,
    dropdownPrefixCls: string,
    columns: ColumnProps<any>[],
    locale: TableLocale
  ) {
    const { sortOrder } = this.state;
    return treeMap(columns, (originColumn, i) => {
      let column = { ...originColumn };
      let filterDropdown;
      let sortButton;
      let onHeaderCell = column.onHeaderCell;
      const isSortColumn = this.isSortColumn(column);
      if (column.sorter) {
        const isAscend = isSortColumn && sortOrder === 'ascend';
        const isDescend = isSortColumn && sortOrder === 'descend';
        const ascend = isAscend && <Icon type="caret-up" />;
        const descend = isDescend && <Icon type="caret-down" />;

        sortButton = (
            <div
              // title={locale.sortTitle}
              className={classNames(
                `${prefixCls}-column-sorter-inner`,
                ascend && descend && `${prefixCls}-column-sorter-inner-full`,
              )}
              key="sorter"
            >
              {ascend}
              {descend}
            </div>
        );

        onHeaderCell = (col: Column<T>) => {
          let colProps: AdditionalCellProps = {};
          // Get original first
          if (column.onHeaderCell) {
            colProps = {
              ...column.onHeaderCell(col),
            };
          }
          // Add sorter logic
          const onHeaderCellClick = colProps.onClick;
          colProps.onClick = (...args) => {
            this.toggleSortOrder(column);
            if (onHeaderCellClick) {
              onHeaderCellClick(...args);
            }
          };
          return colProps;
        };
      }
      return {
        ...column,
        className: classNames(column.className, {
          'gl-table-column-sorter': true,
          [`${prefixCls}-column-has-actions`]: sortButton || filterDropdown,
          [`${prefixCls}-column-has-filters`]: filterDropdown,
          [`${prefixCls}-column-has-sorters`]: sortButton,
          [`${prefixCls}-column-sort`]: isSortColumn && sortOrder,
        }),
        title: [
          <div key="title" className={sortButton ? `${prefixCls}-column-sorters` : undefined}>
            <span className={`${prefixCls}-column-title`}>
              {this.renderColumnTitle(column.title)}
            </span>
            <span className={`${prefixCls}-column-sorter`}>{sortButton}</span>
          </div>,
          filterDropdown,
        ],
        onHeaderCell,
      };
    });
  }

  toggleSortOrder(column: ColumnProps<T>) {
    if (!column.sorter) {
      return;
    }
    const sortDirections =
      column.sortDirections || (this.props.sortDirections as SortOrder[]);
    const { sortOrder, sortColumn, pagination } = this.state;
    // only allow sort one column
    let newSortOrder: "descend" | "ascend" | undefined;
    // select another column，ignore sortOrder state
    if (this.isSameColumn(sortColumn, column) && sortOrder !== undefined) {
      // sort by sortDirections
      const methodIndex = sortDirections.indexOf(sortOrder) + 1;
      newSortOrder =
        methodIndex === sortDirections.length
          ? sortDirections[0]
          : sortDirections[methodIndex];
    } else {
      newSortOrder = sortDirections[0];
    }

    const newState = {
      sortOrder: newSortOrder,
      sortColumn: newSortOrder ? column : null,
      pagination
    };

    // Controlled
    if (this.getSortOrderColumns().length === 0) {
      this.setState(newState);
    }

    const { onChange } = this.props;
    if (onChange) {
      onChange.apply(
        null,
        this.prepareParamsArguments({
          ...this.state,
          ...newState
        })
      );
    }
  }

  // toggleSortOrder(order: ColumnSortOrder, column: ColumnProps<T>) {
  //     let { sortColumn, sortOrder } = this.state;
  //     // only allow sort one column
  //     let isSortColumn = this.isSortColumn(column);
  //     if (!isSortColumn) {  // current column has no sorting
  //         sortOrder = order;
  //         sortColumn = column;
  //     } else {                      // current column has been sorted
  //         // if (sortOrder === order) {  // toggle to no sorting state
  //         //     sortOrder = '';
  //         //     sortColumn = null;
  //         // } else {                    // toggle to sorting state
  //         //     sortOrder = order;
  //         // }
  //         sortOrder = order;  // toggle to sorting state
  //     }
  //     const newState = {
  //         sortOrder,
  //         sortColumn,
  //     };

  //     // controlled
  //     if (this.getSortOrderColumns().length === 0) {
  //         this.setState(newState);
  //     }

  //     const onChange = this.props.onChange;
  //     if (onChange) {
  //         onChange.apply(null, this.prepareParamsArguments({
  //             ...this.state,
  //             ...newState,
  //         }));
  //     }
  // }
}
