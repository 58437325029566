import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
 
export const useStateWithRef = <T>(initState: T) => {
  const [state, updateState] = useState(initState);
  const stateRef = useRef<T>(initState);
  const stateLiveRef = useRef<T>(initState); // To which state will be set.
 
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
 
  const setState = (value: React.SetStateAction<T>) => {
    const newValue =
      typeof value === 'function'
        ? (value as any)(stateLiveRef.current)
        : value;
    stateLiveRef.current = newValue;
    updateState(stateLiveRef.current);
  };
 
  return [state, setState, stateRef, stateLiveRef] as [
    T,
    React.Dispatch<React.SetStateAction<T>>,
    MutableRefObject<T>,
    MutableRefObject<T>,
  ];
};